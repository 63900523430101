var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "adminPanel", attrs: { flat: "" } },
    [
      _c(
        "v-row",
        { staticClass: "headerRow", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c("v-card-title", { staticClass: "administrationHeader" }, [
                _vm._v(" " + _vm._s(_vm.$t("app.routes.administration")) + " "),
              ]),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "12", md: "6", align: "center" } }, [
            _c(
              "div",
              { staticClass: "tabs" },
              [
                _c(
                  "v-tabs",
                  {
                    staticClass: "tabStructure",
                    attrs: { centered: "", color: "blue" },
                    model: {
                      value: _vm.tab,
                      callback: function ($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab",
                    },
                  },
                  [
                    _c(
                      "v-tab",
                      { key: "accounts", attrs: { "data-cy": "accountTable" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("administrationPanel.accounts")) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "v-tab",
                      {
                        key: "services",
                        attrs: { "data-cy": "servicesTable" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("administrationPanel.services")) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tab-item", { key: "accounts" }, [_c("account-table")], 1),
          _c(
            "v-tab-item",
            { key: "services" },
            [_c("third-party-table"), _c("tsi-table")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }