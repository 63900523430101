var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        title: _vm.$t("subscriptionCancel.cancelCard.title"),
        "data-cy": "cancelSubscriptionDialog",
        fullscreen: _vm.$vuetify.breakpoint.xs,
        persistentOverlay: true,
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("subscription-cancel-actions", {
                attrs: { activationId: _vm.activationId },
                on: { close: _vm.close },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_c("subscription-cancel-card")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }