var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _vm.item.allowCancellation
                ? _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "menu-dropdown black--text",
                        attrs: {
                          text: "",
                          "data-cy": "subscriptionMenuDropdown",
                        },
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                    1
                  )
                : _vm._e(),
            ]
          },
        },
      ]),
    },
    [
      _vm.item.allowCancellation
        ? _c(
            "v-list",
            [
              _c(
                "v-tooltip",
                {
                  attrs: { left: "", disabled: !_vm.isLogInAs },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              "div",
                              _vm._g({}, on),
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: {
                                      disabled: _vm.isLogInAs,
                                      link: "",
                                      "data-cy": "cancelSubscriptionActivator",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.$emit(
                                          "cancelSubscriptionOpen",
                                          _vm.item.activationId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [_vm._v("mdi-close")]),
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "manageSubscriptions.menu.cancelSubscription"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2954001712
                  ),
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("manageSubscriptions.unavailableForLogInAs")
                      )
                    ),
                  ]),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { left: "", disabled: !_vm.isLogInAs },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              "div",
                              _vm._g({}, on),
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: {
                                      disabled: _vm.isLogInAs,
                                      link: "",
                                      "data-cy":
                                        "subscriptionBillingInfoActivator",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.$emit(
                                          "subscriptionBillingInfoOpen",
                                          _vm.item.activationId,
                                          _vm.item.subscriptionId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [_vm._v("mdi-credit-card")]),
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "manageSubscriptions.menu.updateBilling"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2786406196
                  ),
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("manageSubscriptions.unavailableForLogInAs")
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }