var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        title: _vm.$t("map.deviceAlerts.title"),
        "data-cy": "deviceAlertsDialog",
        fullscreen: _vm.$vuetify.breakpoint.xs,
        persistentOverlay: true,
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u(
        [
          _vm.deleteViewOpen
            ? {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "v-card-title",
                      { attrs: { "data-cy": "deleteAlertHeader" } },
                      [
                        _c("v-icon", { staticClass: "header-icon" }, [
                          _vm._v("mdi-delete"),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("map.deviceAlerts.delete.title")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : !_vm.updateViewOpen && !_vm.isEnableNotification
            ? {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "v-card-title",
                      { attrs: { "data-cy": "alertSetupHeader" } },
                      [
                        _c("v-icon", { staticClass: "header-icon" }, [
                          _vm._v("mdi-bell"),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("map.deviceAlerts.title")) + " "
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : _vm.updateViewOpen &&
              _vm.editingAlert &&
              !_vm.isEnableNotification
            ? {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "v-card-title",
                      [
                        _c("v-icon", { staticClass: "header-icon" }, [
                          _vm._v("mdi-pencil"),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("map.deviceAlerts.editTitle")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : _vm.updateViewOpen && _vm.addingAlert && !_vm.isEnableNotification
            ? {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "v-card-title",
                      [
                        _c("v-icon", { staticClass: "header-icon" }, [
                          _vm._v("mdi-bell-plus"),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("map.deviceAlerts.addTitle")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : _vm.isEnableNotification && !_vm.isNotificationsTitle
            ? {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "v-card-title",
                      [
                        _c("v-icon", { staticClass: "header-icon" }, [
                          _vm._v("mdi-bell-plus"),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("map.deviceAlerts.enableNotificationTitle")
                            ) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : _vm.isNotificationsTitle
            ? {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "v-card-title",
                      [
                        _c("v-icon", { staticClass: "header-icon" }, [
                          _vm._v("mdi-bell-plus"),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("map.deviceAlerts.notificationTitle")
                            ) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : _vm.addViewOpen
            ? {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "v-card-title",
                      { attrs: { "data-cy": "addTitle" } },
                      [
                        _c("v-icon", { staticClass: "header-icon" }, [
                          _vm._v("mdi-bell-plus"),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("map.deviceAlerts.addTitle")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : null,
          {
            key: "actions",
            fn: function () {
              return [
                _vm.deleteViewOpen
                  ? [
                      _c(
                        "v-btn",
                        {
                          staticClass: "black--text",
                          attrs: {
                            outlined: "",
                            text: "",
                            "data-cy": "deleteCancelButton",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.cancelDeleteAlert()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary white--text",
                          class: { disabled: _vm.alerts === null },
                          attrs: {
                            disabled: _vm.alerts === null,
                            text: "",
                            "data-cy": "deleteAlertButton",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteAlert()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("buttons.delete")) + " ")]
                      ),
                    ]
                  : !_vm.updateViewOpen && !_vm.isEnableNotification
                  ? [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            bottom: "",
                            disabled: _vm.hasRemainingAlerts,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "black--text",
                                          class: [
                                            {
                                              restrictedContent:
                                                !_vm.hasRemainingAlerts,
                                            },
                                          ],
                                          attrs: {
                                            outlined: "",
                                            text: "",
                                            "data-cy": "addButton",
                                          },
                                          on: { click: _vm.startAlertAdd },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("buttons.add")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("map.deviceAlerts.upgradeTooltip")
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary white--text",
                          attrs: { text: "", "data-cy": "doneButton" },
                          on: { click: _vm.close },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("buttons.done")) + " ")]
                      ),
                    ]
                  : _vm.updateViewOpen && !_vm.isEnableNotification
                  ? [
                      _c(
                        "v-btn",
                        {
                          staticClass: "black--text",
                          attrs: {
                            outlined: "",
                            text: "",
                            "data-cy": "cancelAddButton",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.closeUpdateView()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary white--text",
                          attrs: {
                            disabled: !_vm.fieldsValid,
                            text: "",
                            "data-cy": "saveButton",
                          },
                          on: { click: _vm.updateAlert },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("buttons.save")) + " ")]
                      ),
                    ]
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [
      _vm.deleteViewOpen
        ? _c(
            "div",
            [
              _c("v-card", { attrs: { flat: "" } }, [
                _vm._v(_vm._s(_vm.$t("map.deviceAlerts.delete.message"))),
              ]),
            ],
            1
          )
        : !_vm.updateViewOpen &&
          _vm.alerts === null &&
          !_vm.isEnableNotification
        ? _c(
            "div",
            { staticClass: "text-center emptyMessage" },
            [
              _c("v-progress-circular", {
                attrs: {
                  indeterminate: "",
                  color: "primary",
                  "data-cy": "alertLoadingSpinner",
                },
              }),
            ],
            1
          )
        : !_vm.updateViewOpen &&
          _vm.alerts.length === 0 &&
          !_vm.isEnableNotification
        ? _c(
            "div",
            {
              staticClass: "text-center emptyMessage",
              attrs: { "data-cy": "emptyAlertMessage" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("map.deviceAlerts.empty")) + " ")]
          )
        : !_vm.updateViewOpen &&
          _vm.alerts.length > 0 &&
          !_vm.isEnableNotification
        ? _c(
            "v-form",
            _vm._l(_vm.alerts, function (alert, index) {
              return _c(
                "v-list-item",
                {
                  key: index,
                  style:
                    index === _vm.alerts.length - 1
                      ? "padding-bottom: 8px; padding-right: 0;"
                      : "border-bottom: 1px solid rgba(0,0,0,0.26); padding-bottom: 8px; margin-bottom: 16px; padding-right: 0;",
                },
                [
                  _c(
                    "v-list-item-content",
                    { staticStyle: { "padding-top": "0" } },
                    [
                      _c(
                        "v-card-actions",
                        {
                          staticStyle: {
                            "padding-left": "0",
                            "margin-top": "0",
                            "padding-right": "0px",
                          },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticStyle: { "font-size": "17px" },
                              attrs: { "data-cy": "listTitle" },
                            },
                            [_vm._v(" " + _vm._s(alert.title) + " ")]
                          ),
                          _c("v-spacer"),
                          !_vm.$vuetify.breakpoint.xs
                            ? [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "icon-button",
                                    attrs: { text: "", "data-cy": "editIcon" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.startAlertEdit(alert)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { left: "", light: "" } },
                                      [_vm._v("mdi-pencil")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("buttons.edit")) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "icon-button",
                                    attrs: {
                                      text: "",
                                      "data-cy": "deleteIcon",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmDeleteAlert(alert.id)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { left: "", light: "" } },
                                      [_vm._v("mdi-delete")]
                                    ),
                                    _c("div", { attrs: { "data-cy": "" } }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("buttons.delete")) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c("br"),
                      _c("br"),
                      _c("v-list-item-subtitle", [
                        _c(
                          "span",
                          { attrs: { "data-cy": "listCreationDate" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("map.deviceAlerts.fields.creationDate")
                              ) + ": "
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          { attrs: { "data-cy": "listCreationDateData" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  new Date(alert.createdAt).toLocaleString()
                                )
                            ),
                          ]
                        ),
                      ]),
                      _c("v-list-item-subtitle", [
                        _c(
                          "span",
                          { attrs: { "data-cy": "listAlertEnabled" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("map.deviceAlerts.fields.enabled")
                              ) + ": "
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          { attrs: { "data-cy": "listAlertEnabledData" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  alert.enabled
                                    ? _vm.$t("boolean.yes")
                                    : _vm.$t("boolean.no")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "v-list-item-subtitle",
                        { attrs: { "data-cy": "listAlertLevel" } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("map.deviceAlerts.fields.level")) +
                                ": "
                            ),
                          ]),
                          alert.severity === "standard"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "map.deviceAlerts.fields.levels.standard"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : alert.severity === "warning"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "map.deviceAlerts.fields.levels.warning"
                                    )
                                  )
                                ),
                              ])
                            : alert.severity === "critical"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "map.deviceAlerts.fields.levels.critical"
                                    )
                                  )
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "map.deviceAlerts.fields.levels.none"
                                    )
                                  )
                                ),
                              ]),
                        ]
                      ),
                      _c(
                        "span",
                        [
                          _c("v-list-item-subtitle", [
                            _c(
                              "span",
                              { attrs: { "data-cy": "listAlertSensor" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("map.deviceAlerts.fields.sensor")
                                  ) + ": "
                                ),
                              ]
                            ),
                            alert.condition.field
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "map.deviceAlerts.fields.sensors." +
                                            alert.condition.field
                                        )
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "map.deviceAlerts.fields.sensors.none"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                          ]),
                          _c(
                            "v-list-item-subtitle",
                            { attrs: { "data-cy": "listAlertCondition" } },
                            [
                              alert.condition.operator === "lt"
                                ? _c(
                                    "span",
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "map.deviceAlerts.fields.condition"
                                            )
                                          ) + ": "
                                        ),
                                      ]),
                                      _c("i18n", {
                                        attrs: {
                                          path: "map.deviceAlerts.fields.conditions.lt",
                                          tag: "span",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        alert.condition.value
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : alert.condition.operator === "lte"
                                ? _c(
                                    "span",
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "map.deviceAlerts.fields.condition"
                                            )
                                          ) + ": "
                                        ),
                                      ]),
                                      _c("i18n", {
                                        attrs: {
                                          path: "map.deviceAlerts.fields.conditions.lte",
                                          tag: "span",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        alert.condition.value
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : alert.condition.operator === "eq"
                                ? _c(
                                    "span",
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "map.deviceAlerts.fields.condition"
                                            )
                                          ) + ": "
                                        ),
                                      ]),
                                      _c("i18n", {
                                        attrs: {
                                          path: "map.deviceAlerts.fields.conditions.eq",
                                          tag: "span",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        alert.condition.value
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : alert.condition.operator === "gte"
                                ? _c(
                                    "span",
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "map.deviceAlerts.fields.condition"
                                            )
                                          ) + ": "
                                        ),
                                      ]),
                                      _c("i18n", {
                                        attrs: {
                                          path: "map.deviceAlerts.fields.conditions.gte",
                                          tag: "span",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        alert.condition.value
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : alert.condition.operator === "gt"
                                ? _c(
                                    "span",
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "map.deviceAlerts.fields.condition"
                                            )
                                          ) + ": "
                                        ),
                                      ]),
                                      _c("i18n", {
                                        attrs: {
                                          path: "map.deviceAlerts.fields.conditions.gt",
                                          tag: "span",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "value",
                                              fn: function () {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        alert.condition.value
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _c("span", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "map.deviceAlerts.fields.condition"
                                          )
                                        ) + ": "
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "map.deviceAlerts.fields.conditions.none"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                            ]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { attrs: { "data-cy": "listFieldDeliverTo" } },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("map.deviceAlerts.fields.deliverTo")
                                  ) + ": "
                                ),
                              ]),
                              alert.sendSms && _vm.phone && _vm.phone.number
                                ? _c("span", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "map.deviceAlerts.fields.mobile"
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm.$vuetify.breakpoint.smAndUp
                                      ? _c("span", [
                                          _vm._v(
                                            " (" +
                                              _vm._s(_vm.phone.number) +
                                              ") "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              alert.sendSms && alert.sendEmail
                                ? _c("span", [_vm._v(", ")])
                                : _vm._e(),
                              alert.sendEmail
                                ? _c("span", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "map.deviceAlerts.fields.email"
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm.$vuetify.breakpoint.smAndUp
                                      ? _c("span", [
                                          _vm._v(
                                            " (" +
                                              _vm._s(
                                                _vm.$store.state.userProfile
                                                  .user.email
                                              ) +
                                              ") "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { attrs: { "data-cy": "listFieldMessage" } },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("map.deviceAlerts.fields.message")
                                  ) + ": "
                                ),
                              ]),
                              _c("span", [_vm._v(_vm._s(alert.userMessage))]),
                            ]
                          ),
                          _c("v-list-item-subtitle", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("map.deviceAlerts.fields.frequency")
                                ) + ": "
                              ),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.getFrequency(alert.frequencyType))
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm.$vuetify.breakpoint.xs
                        ? _c(
                            "v-card-actions",
                            {
                              staticStyle: {
                                "margin-top": "0px",
                                "padding-right": "0px",
                              },
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "icon-button",
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.startAlertEdit(alert)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { left: "", light: "" } },
                                    [_vm._v("mdi-pencil")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("buttons.edit")) + " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "icon-button",
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmDeleteAlert(alert.id)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "data-cy": "deleteButton",
                                        left: "",
                                        light: "",
                                      },
                                    },
                                    [_vm._v("mdi-delete")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("buttons.delete")) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm.updateViewOpen
        ? _c("update-alert", {
            attrs: {
              currentAlert: _vm.currentAlert,
              rules: _vm.rules,
              isNotificationEnabled: _vm.isNotificationBtnEnabled,
              isEditingAlert: _vm.editingAlert,
              isSmsDisabled: _vm.isSmsDisabled,
              isEmailDisabled: _vm.isEmailDisabled,
              phone: _vm.phone,
              deviceId: _vm.deviceId,
              measurements: _vm.sensors,
              helpText: _vm.helpText,
            },
            on: {
              toggleNotification: _vm.toggleNotification,
              onDisclaimerAgree: _vm.toggleNotificationsTitle,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }