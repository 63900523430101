var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "studyManagerPage ma-6" },
    [
      _c(
        "v-row",
        { staticClass: "pl-2 pr-2" },
        [
          _c(
            "v-col",
            [
              _c("fusion-study-manager", {
                ref: "studyManager",
                attrs: {
                  columns: _vm.columns,
                  detailsDialogFields: _vm.detailsDialogFields,
                  showDetailsBtn: true,
                  showEditBtn: true,
                  showDeleteBtn: true,
                  showDoneBtn: false,
                  showSendToReportCreatorBtn: false,
                  studiesLeftToComplete: _vm.studiesLeftToComplete,
                  sortBy: _vm.sortBy,
                  sortDesc: _vm.sortDesc,
                  intervalRequest: _vm.intervalRequest,
                  "data-cy": "fusionStudyManager",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "h1",
                          {
                            staticClass: "pl-4 pt-4 studyManagerTitle",
                            attrs: { "data-cy": "studyManagerTitle" },
                          },
                          [_vm._v(_vm._s(_vm.$t("studyManager.title")))]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }