var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "unregister-warning", attrs: { flat: "" } },
    [
      _c("div", { staticClass: "unregister-subtitle" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("subscriptionCancel.cancelCard.warningSubtitle")) +
            " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "unregister-message" },
        [
          [
            [
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("subscriptionCancel.cancelCard.message.partOne")
                    ) +
                    " "
                ),
              ]),
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("subscriptionCancel.cancelCard.message.partTwo")
                    ) +
                    " "
                ),
              ]),
            ],
          ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }