var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "content-wrapper " + (_vm.hover ? "hovered" : "") },
    [
      _c("div", { staticClass: "sub-wrapper", class: _vm.cssStyle }, [
        _c(
          "div",
          {
            staticClass: "content",
            on: {
              mouseover: function ($event) {
                _vm.hover = true
              },
              mouseleave: function ($event) {
                _vm.hover = false
              },
              click: _vm.onClickHandler,
            },
          },
          [_vm._t("content")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }