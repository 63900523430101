var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "devPanel" },
    [
      _c(
        "v-row",
        { staticClass: "headerRow" },
        [
          _c(
            "v-col",
            { staticClass: "headerCol", attrs: { cols: "12", md: "3" } },
            [
              _c("v-card-title", { staticClass: "devHeader" }, [
                _vm._v(" " + _vm._s(_vm.$t("app.routes.developer")) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", align: "center" } },
            [_c("emulatorPanel")],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "9", align: "center" } },
            [_c("emulatorTable")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }