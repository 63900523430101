var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-icon", {
    attrs: {
      showIcon: _vm.showIcon,
      icon: _vm.batteryIcon,
      iconColor: _vm.color,
      imgAlt: "Battert Icon",
      iconText: _vm.iconText,
      tooltipContentClass: "battery-status-tooltip",
      dataCy: "batteryStatus",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }