var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "zero-actions-row" },
    [
      _c(
        "v-btn",
        {
          attrs: { outlined: "", text: "", "data-cy": "zeroCancelButton" },
          on: {
            click: function ($event) {
              return _vm.cancel()
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
      ),
      _c("v-spacer"),
      _c(
        "v-btn",
        {
          staticClass: "primary white--text",
          attrs: { text: "", "data-cy": "zeroConfirmButton" },
          on: {
            click: function ($event) {
              return _vm.confirm()
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("buttons.ok")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }