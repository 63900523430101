var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-icon", {
    attrs: {
      showIcon: _vm.showIcon,
      imgSrc: "/img/icons/live_studies_icon.svg",
      imgAlt: "Live Studies Icon",
      iconText: _vm.iconText,
      tooltipContentClass: "live-studies-status-tooltip",
      dataCy: "liveStudiesStatus",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }