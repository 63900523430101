var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        title: _vm.$t("map.mapSettings.title"),
        icon: "mdi-settings",
        "max-width": 320,
        persistent: true,
        "content-class": _vm.$vuetify.breakpoint.xs ? "" : "map-settings",
        "data-cy": "mapSettingsHeader",
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary white--text",
                  attrs: { text: "", "data-cy": "mapSettingsClose" },
                  on: { click: _vm.close },
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.done")) + " ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        {
          staticClass: "card-content",
          attrs: { "data-cy": "mapSettingsDialog" },
        },
        [
          _c("v-switch", { staticStyle: { display: "none" } }),
          _c("v-select", {
            attrs: {
              items: _vm.measurementTypeItems,
              "item-text": "text",
              "item-value": "value",
              label: _vm.$t("map.mapSettings.measurementType"),
              "data-cy": "mapSettingsMeasurementTypeSelect",
            },
            on: { change: _vm.changeMeasurementType },
            model: {
              value: _vm.measurementType,
              callback: function ($$v) {
                _vm.measurementType = $$v
              },
              expression: "measurementType",
            },
          }),
          _c("v-select", {
            staticClass: "mb-4",
            attrs: {
              value: _vm.selectedDeviceModels,
              items: _vm.deviceModelItems,
              "item-text": "text",
              "item-value": "value",
              multiple: "",
              label: _vm.$t("map.mapSettings.deviceModels.label"),
              placeholder: _vm.$t("map.mapSettings.deviceModels.placeholder"),
              hint: _vm.$t("map.mapSettings.deviceModels.hint"),
              "persistent-hint": "",
              "data-cy": "mapSettingsDeviceSelect",
            },
            on: { change: _vm.changeDeviceModels },
          }),
          _c("v-switch", {
            attrs: {
              color: "primary",
              label: _vm.$t("map.mapSettings.showMine"),
              "data-cy": "mapSettingsShowMySensors",
            },
            on: { change: _vm.changeMine },
            model: {
              value: _vm.toggles.showMine,
              callback: function ($$v) {
                _vm.$set(_vm.toggles, "showMine", $$v)
              },
              expression: "toggles.showMine",
            },
          }),
          _c("v-switch", {
            attrs: {
              color: "primary",
              label: _vm.$t("map.mapSettings.showShared"),
              "data-cy": "mapSettingsShowSharedSensors",
            },
            on: { change: _vm.changeShared },
            model: {
              value: _vm.toggles.showShared,
              callback: function ($$v) {
                _vm.$set(_vm.toggles, "showShared", $$v)
              },
              expression: "toggles.showShared",
            },
          }),
          _c("v-switch", {
            attrs: {
              color: "primary",
              label: _vm.$t("map.mapSettings.showPublic"),
              "data-cy": "mapSettingsShowPublicSensors",
            },
            on: { change: _vm.changePublic },
            model: {
              value: _vm.toggles.showPublic,
              callback: function ($$v) {
                _vm.$set(_vm.toggles, "showPublic", $$v)
              },
              expression: "toggles.showPublic",
            },
          }),
          _c(
            "div",
            { staticClass: "secondary-indicator" },
            [
              _c("v-switch", {
                attrs: {
                  color: "primary",
                  label: _vm.$t("map.mapSettings.showOutdoor"),
                  "data-cy": "mapSettingsShowOutdoor",
                },
                on: { change: _vm.changeOutdoor },
                model: {
                  value: _vm.toggles.showOutdoor,
                  callback: function ($$v) {
                    _vm.$set(_vm.toggles, "showOutdoor", $$v)
                  },
                  expression: "toggles.showOutdoor",
                },
              }),
              _c("div", { staticClass: "device-type-indicator" }, [
                _c("img", {
                  attrs: {
                    src: "img/map/pin-blank-outdoor.png",
                    alt: "Outdoor Device Pin",
                  },
                }),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "secondary-indicator" },
            [
              _c("v-switch", {
                attrs: {
                  color: "primary",
                  label: _vm.$t("map.mapSettings.showIndoor"),
                  "data-cy": "mapSettingsShowIndoor",
                },
                on: { change: _vm.changeIndoor },
                model: {
                  value: _vm.toggles.showIndoor,
                  callback: function ($$v) {
                    _vm.$set(_vm.toggles, "showIndoor", $$v)
                  },
                  expression: "toggles.showIndoor",
                },
              }),
              _c("div", { staticClass: "device-type-indicator" }, [
                _c("img", {
                  staticClass: "indoor-type",
                  attrs: {
                    src: "img/map/pin-blank-indoor.png",
                    alt: "Indoor Device Pin",
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }