var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    {
      attrs: { link: "", disabled: !_vm.hasPermissions },
      on: { click: _vm.viewDeviceOnMap },
    },
    [
      _c("v-icon", [_vm._v("mdi-map-marker")]),
      _c("v-list-item-title", { attrs: { "data-cy": "deviceViewOnMap" } }, [
        _vm._v(_vm._s(_vm.$t("manageDevices.actions.viewOnMap"))),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }