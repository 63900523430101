var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style:
        "height: calc(100vh - " +
        (this.$vuetify.breakpoint.mdAndUp ? "64px" : "56px") +
        ");",
    },
    [
      _c("v-map"),
      !_vm.dialogs.reactivateAccount
        ? _c(
            "div",
            { staticClass: "add-device" },
            [
              _c("register-move-device"),
              _c("register-device-map-button", {
                on: {
                  open: function ($event) {
                    _vm.dialogs.registerDevice = true
                  },
                },
              }),
              _c("register-device", {
                attrs: {
                  open: _vm.dialogs.registerDevice,
                  deviceSerial: "",
                  deviceModel: "",
                },
                on: {
                  close: function ($event) {
                    _vm.dialogs.registerDevice = false
                  },
                  deviceCreated: _vm.onDeviceCreated,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "map-legend" }, [_c("map-legend")], 1),
      _c("fusion-reactivate-account", {
        attrs: { open: _vm.dialogs.reactivateAccount },
        on: { close: this.closeReactivateAccount },
      }),
      !_vm.dialogs.reactivateAccount
        ? _c(
            "div",
            [
              _c("fusion-latest-release-note-dialog", {
                attrs: { open: _vm.dialogs.releaseNotes },
                on: {
                  close: function ($event) {
                    _vm.dialogs.releaseNotes = false
                  },
                },
              }),
              _c("subscription-expired", {
                attrs: { open: _vm.dialogs.subscriptionExpired },
                on: {
                  close: function ($event) {
                    _vm.dialogs.subscriptionExpired = false
                  },
                },
              }),
              _c("devices-updated", {
                attrs: {
                  devices: _vm.devicesUpdated,
                  open: _vm.dialogs.devicesUpdated,
                },
                on: {
                  close: function ($event) {
                    _vm.dialogs.devicesUpdated = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("fusion-latest-release-note-dialog", {
        attrs: { open: _vm.dialogs.releaseNotes },
        on: {
          close: function ($event) {
            _vm.dialogs.releaseNotes = false
          },
        },
      }),
      _c("subscription-expired", {
        attrs: { open: _vm.dialogs.subscriptionExpired },
        on: {
          close: function ($event) {
            _vm.dialogs.subscriptionExpired = false
          },
        },
      }),
      _c("devices-updated", {
        attrs: {
          devices: _vm.devicesUpdated,
          open: _vm.dialogs.devicesUpdated,
        },
        on: {
          close: function ($event) {
            _vm.dialogs.devicesUpdated = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }