var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      class: {
        "ma-6": !this.$vuetify.breakpoint.xs,
        "alert-history-card": true,
        "is-mobile": this.isMobile,
      },
    },
    [
      _c("v-card-subtitle", [_vm._v(_vm._s(_vm.$t("alertHistory.title")))]),
      !_vm.$auth.loading &&
      _vm.alertHistory !== null &&
      _vm.alertHistory.length !== 0
        ? _c("v-data-table", {
            class: { mobile: _vm.isMobile },
            attrs: {
              headers: _vm.headers,
              items: _vm.alertHistory,
              "mobile-breakpoint": _vm.$vuetify.breakpoint.thresholds.sm,
              "header-props": _vm.headerProps,
              search: _vm.customTableFilter,
              "show-select": "",
              options: _vm.tableOptions,
              "sort-by": _vm.sortedBy,
              "sort-desc": _vm.sortedDesc,
              itemsPerPage: _vm.itemsPerPage,
              "footer-props": {
                "items-per-page-text": _vm.isMobile
                  ? _vm.$t("tables.rows")
                  : _vm.$t("tables.rowsPerPage"),
                itemsPerPageOptions: [10, 25, 50],
              },
            },
            on: {
              "current-items": function ($event) {
                return _vm.setItems($event)
              },
              "update:sortBy": function ($event) {
                _vm.sortedBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortedBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.sortedDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortedDesc = $event
              },
              "update:itemsPerPage": function ($event) {
                _vm.itemsPerPage = $event
              },
              "update:items-per-page": function ($event) {
                _vm.itemsPerPage = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function () {
                    return [
                      !_vm.selected.length
                        ? _c(
                            "div",
                            { staticClass: "table-bar" },
                            [
                              _c("v-text-field", {
                                staticClass: "search-field",
                                style: _vm.isMobile ? "" : "width: 200px",
                                attrs: {
                                  "prepend-icon": "mdi-magnify",
                                  label: "Search",
                                  "single-line": "",
                                  "hide-details": "",
                                  "data-cy": "searchField",
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                              _c("v-spacer"),
                              _c(
                                "span",
                                { staticClass: "export" },
                                [_c("export-notifications")],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "table-bar",
                              class: { "delete-mode": !!_vm.selected.length },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "selection-message",
                                  attrs: { "data-cy": "deleteText" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "alertHistory.selectionMessage",
                                          { value: _vm.selected.length }
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("v-spacer"),
                              _c(
                                "base-dialog",
                                {
                                  attrs: {
                                    open: _vm.deleteOpen,
                                    fullscreen: _vm.$vuetify.breakpoint.xs,
                                    title: _vm.$t(
                                      "alertHistory.deleteDialog.title",
                                      { plurality: _vm.plurality }
                                    ),
                                    persistentOverlay: true,
                                    icon: "mdi-delete",
                                    padded: "",
                                  },
                                  on: {
                                    close: function ($event) {
                                      return _vm.deleteClose()
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "alert-delete",
                                                  attrs: {
                                                    text: "",
                                                    "data-cy": "deleteButton",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      _vm.deleteOpen = true
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-delete"),
                                                ]),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "padding-left": "4px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "buttons.delete"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "actions",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "black--text",
                                                attrs: {
                                                  outlined: "",
                                                  text: "",
                                                  "data-cy":
                                                    "deleteCancelButtonModal",
                                                  disabled:
                                                    _vm.deleteInProgress,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteClose()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("buttons.cancel")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("v-spacer"),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "primary white--text",
                                                attrs: {
                                                  text: "",
                                                  "data-cy":
                                                    "deleteButtonModal",
                                                  loading: _vm.deleteInProgress,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteNotifications()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("buttons.delete")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1003068081
                                  ),
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "alertHistory.deleteDialog.message",
                                          {
                                            value: _vm.selected.length,
                                            plurality: _vm.plurality,
                                          }
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "header.data-table-select",
                  fn: function (ref) {
                    var on = ref.on
                    var props = ref.props
                    return [
                      _c(
                        "v-checkbox",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "master-select",
                              attrs: {
                                color: "primary",
                                "data-cy": "checkboxDesktop",
                              },
                              model: {
                                value: _vm.selectAll,
                                callback: function ($$v) {
                                  _vm.selectAll = $$v
                                },
                                expression: "selectAll",
                              },
                            },
                            "v-checkbox",
                            props,
                            false
                          ),
                          on
                        )
                      ),
                    ]
                  },
                },
                {
                  key: "body",
                  fn: function (ref) {
                    var items = ref.items
                    return [
                      !_vm.isMobile
                        ? _c(
                            "tbody",
                            _vm._l(items, function (item) {
                              return _c("tr", { key: item.id }, [
                                _c(
                                  "td",
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        color: "primary",
                                        value: item.id,
                                      },
                                      model: {
                                        value: _vm.selected,
                                        callback: function ($$v) {
                                          _vm.selected = $$v
                                        },
                                        expression: "selected",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "min-width": "80px",
                                      "max-width": "120px",
                                    },
                                    attrs: { "data-cy": "titleColumnData" },
                                  },
                                  [_vm._v(" " + _vm._s(item.title) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-xs-right",
                                    staticStyle: { "min-width": "130px" },
                                    attrs: { "data-cy": "triggeredOnData" },
                                  },
                                  [_vm._v(" " + _vm._s(item.triggeredAt) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-xs-right",
                                    staticStyle: {
                                      "min-width": "150px",
                                      "max-width": "200px",
                                    },
                                    attrs: { "data-cy": "deviceColumnData" },
                                  },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          bottom: "",
                                          "max-width": 600,
                                          disabled:
                                            !_vm.isNameOverflowing[item.id],
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g(
                                                      {
                                                        ref: item.id,
                                                        refInFor: true,
                                                        staticClass:
                                                          "device-name",
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.deviceName
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.deviceName)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-xs-right",
                                    staticStyle: {
                                      "min-width": "150px",
                                      "max-width": "200px",
                                    },
                                    attrs: { "data-cy": "locationColumnData" },
                                  },
                                  [
                                    item.deviceLocationName
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              bottom: "",
                                              "max-width": 600,
                                              disabled:
                                                !_vm.isNameOverflowing[item.id],
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "div",
                                                        _vm._g(
                                                          {
                                                            ref: item.id,
                                                            refInFor: true,
                                                            staticClass:
                                                              "device-location",
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.deviceLocationName
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(item.deviceLocationName)
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "not-available-text" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "alertHistory.notAvailable"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-xs-right",
                                    staticStyle: { "max-width": "160px" },
                                    attrs: { "data-cy": "modelColumnData" },
                                  },
                                  [_vm._v(" " + _vm._s(item.deviceModel) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-xs-right",
                                    staticStyle: {
                                      "min-width": "110px",
                                      "max-width": "220px",
                                    },
                                    attrs: { "data-cy": "conditionColumnData" },
                                  },
                                  [_vm._v(" " + _vm._s(item.condition) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-xs-right",
                                    staticStyle: {
                                      "min-width": "110px",
                                      "max-width": "220px",
                                    },
                                    attrs: { "data-cy": "messageColumnData" },
                                  },
                                  [_vm._v(" " + _vm._s(item.message) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-xs-right",
                                    staticStyle: { "min-width": "110px" },
                                    attrs: {
                                      "data-cy": "alertLevelColumnData",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.severity) + " ")]
                                ),
                              ])
                            }),
                            0
                          )
                        : _c(
                            "tbody",
                            _vm._l(items, function (item) {
                              return _c("tr", { key: item.id }, [
                                _c(
                                  "td",
                                  { staticClass: "mobile-list" },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "1" } },
                                          [
                                            _c("v-checkbox", {
                                              attrs: {
                                                color: "primary",
                                                value: item.id,
                                              },
                                              model: {
                                                value: _vm.selected,
                                                callback: function ($$v) {
                                                  _vm.selected = $$v
                                                },
                                                expression: "selected",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("v-col", { attrs: { cols: "11" } }, [
                                          _c(
                                            "ul",
                                            { staticClass: "flex-content" },
                                            [
                                              _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "flex-item item-title",
                                                },
                                                [_vm._v(_vm._s(item.title))]
                                              ),
                                              _c(
                                                "li",
                                                { staticClass: "flex-item" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "alertHistory.tableHeaders.triggeredOn"
                                                          )
                                                        ) +
                                                        ":  "
                                                    ),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          new Date(
                                                            item.triggeredAt
                                                          ).toLocaleString()
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "li",
                                                { staticClass: "flex-item" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "alertHistory.tableHeaders.deviceName"
                                                          )
                                                        ) +
                                                        ":  "
                                                    ),
                                                  ]),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: {
                                                        bottom: "",
                                                        "max-width": 600,
                                                        disabled:
                                                          !_vm
                                                            .isNameOverflowing[
                                                            item.id
                                                          ],
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  _vm._g(
                                                                    {
                                                                      ref: item.id,
                                                                      refInFor: true,
                                                                      staticClass:
                                                                        "device-name",
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.deviceName
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.deviceName
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "li",
                                                { staticClass: "flex-item" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "alertHistory.tableHeaders.deviceLocationName"
                                                          )
                                                        ) +
                                                        ":  "
                                                    ),
                                                  ]),
                                                  item.deviceLocationName
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            bottom: "",
                                                            "max-width": 600,
                                                            disabled:
                                                              !_vm
                                                                .isNameOverflowing[
                                                                item.id
                                                              ],
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "span",
                                                                      _vm._g(
                                                                        {
                                                                          ref: item.id,
                                                                          refInFor: true,
                                                                          staticClass:
                                                                            "device-location",
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.deviceLocationName
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.deviceLocationName
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "not-available-text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "alertHistory.notAvailable"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "li",
                                                { staticClass: "flex-item" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "alertHistory.tableHeaders.deviceModel"
                                                          )
                                                        ) +
                                                        ":  "
                                                    ),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.deviceModel)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "li",
                                                { staticClass: "flex-item" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "alertHistory.tableHeaders.condition"
                                                          )
                                                        ) +
                                                        ":  "
                                                    ),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.condition)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "li",
                                                { staticClass: "flex-item" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "alertHistory.tableHeaders.message"
                                                          )
                                                        ) +
                                                        ":  "
                                                    ),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.message)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "li",
                                                { staticClass: "flex-item" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "alertHistory.tableHeaders.alertLevel"
                                                          )
                                                        ) +
                                                        ":  "
                                                    ),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.severity)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            }),
                            0
                          ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        : !_vm.$auth.loading &&
          _vm.alertHistory !== null &&
          _vm.alertHistory.length === 0
        ? _c("div", { staticClass: "text-center emptyMessage" }, [
            _vm._v(" " + _vm._s(_vm.$t("alertHistory.emptyMessage")) + " "),
          ])
        : _c(
            "div",
            { staticClass: "text-center emptyMessage" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }