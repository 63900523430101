var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$store.state.map.movingMode
        ? _c(
            "v-card",
            {
              staticStyle: { right: "0rem", top: "0rem", width: "290px" },
              attrs: {
                "data-cy": "moveDeviceCard",
                coords: JSON.stringify(_vm.currentCoords),
              },
            },
            [
              _c("v-card-title", { staticClass: "move-title" }, [
                _vm._v(_vm._s(_vm.$t("map.moveDevice.title"))),
              ]),
              _c("div", { staticClass: "move-content" }, [
                _vm._v(_vm._s(_vm.$t("map.moveDevice.content"))),
              ]),
              _c(
                "v-card-actions",
                { staticClass: "move-actions", attrs: { pa: "0" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "left black white--text",
                      attrs: {
                        outlined: "",
                        text: "",
                        "data-cy": "cancelMoveButton",
                      },
                      on: { click: _vm.cancelActivation },
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "right primary white--text",
                      attrs: { text: "", "data-cy": "moveDoneButton" },
                      on: { click: _vm.completeActivation },
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.done")))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }