var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-dialog",
        {
          attrs: {
            open: _vm.showDialog,
            persistentOverlay: true,
            icon: "mdi-alert",
          },
          on: { close: _vm.close },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "v-card-title",
                    [
                      _c("v-icon", { staticClass: "header-icon" }, [
                        _vm._v("mdi-map-marker-plus"),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("apiServices.registerDevices")) +
                          " "
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "black--text cancel-btn",
                      attrs: {
                        "data-cy": "closeRegisterDevicesDialog",
                        text: "",
                        outlined: "",
                        disabled: _vm.bulkSpinner,
                      },
                      on: { click: _vm.close },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary white--text",
                      attrs: {
                        text: "",
                        loading: _vm.bulkSpinner,
                        disabled: _vm.submitDisabled,
                        "data-cy": "apiBulkRegisterSubmit",
                      },
                      on: { click: _vm.bulkRegisterExternalDevices },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("buttons.submit")) + " ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            {},
            [
              _c(
                "v-card-text",
                [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("apiServices.registrationFormat"))),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("apiServices.registrationSubscription"))
                    ),
                  ]),
                  _c("v-textarea", {
                    attrs: {
                      placeholder: _vm.$t("apiServices.registerPlaceholder"),
                      rules: [_vm.bulkDevicesValid],
                      outlined: "",
                      "data-cy": "apiBulkDevices",
                    },
                    on: {
                      keypress: function ($event) {
                        return _vm.preventNonNumbers($event)
                      },
                    },
                    model: {
                      value: _vm.bulkDevices,
                      callback: function ($$v) {
                        _vm.bulkDevices = $$v
                      },
                      expression: "bulkDevices",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("access-additional-features-dialog", {
        attrs: {
          open: _vm.dialogs.accessAdditionalFeatures.open,
          addToBoth: _vm.addToBothWebsiteAndDataServices,
          subscriptionTiers: _vm.subscriptionTiers,
          bulkDeviceModels: _vm.bulkDeviceModels,
        },
        on: {
          change: _vm.changeAddToBothWebsiteAndDataServices,
          subTierChange: _vm.changeSubscriptionTiers,
          cancel: _vm.cancelAccessAdditionalFeaturesDialog,
          close: _vm.closeAccessAdditionalFeaturesDialog,
        },
      }),
      _c("api-bulk-registration-info-dialog", {
        attrs: {
          open: _vm.dialogs.apiBulkRegistrationInfo.open,
          isRetrying: _vm.dialogs.apiBulkRegistrationInfo.isRetrying,
          addToBoth: _vm.addToBothWebsiteAndDataServices,
          allowRetry: _vm.allowRetry,
          results: _vm.results,
        },
        on: {
          retry: _vm.retryBulkRegistration,
          close: _vm.closeAPIBulkRegistrationInfoDialog,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }