var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isPublicInactive
    ? _c("GmapMarker", {
        attrs: {
          position: _vm.coords,
          icon: _vm.icon,
          label: _vm.label,
          clickable: _vm.clickable && !_vm.widget,
          draggable: _vm.moving,
          visible: _vm.visible,
          zIndex: _vm.zIndex,
          opacity: _vm.opacity,
          "data-cy": "marker",
          title: _vm.title,
        },
        on: {
          click: _vm.clicked,
          dragend: function ($event) {
            return _vm.dragend($event)
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }