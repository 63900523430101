var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.eolStatus
    ? _c("v-hover", {
        attrs: { "open-delay": 0, "close-delay": 0, value: _vm.val },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (ref) {
                var hover = ref.hover
                return [
                  _c(
                    "span",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "popover-status-icon",
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.val = !_vm.val
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { color: "grey" } }, [
                            _vm._v("mdi-alert-decagram"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { value: hover, bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [_c("span", _vm._g({}, on))]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("device.warnings.sps.eol")) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          false,
          4259787172
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }