var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        error: _vm.error,
        secondaryError: _vm.secondaryError,
        shaded: _vm.isShaded,
        title: _vm.$t("map.deviceSettings.title"),
        icon: "mdi-settings-box",
        "data-cy": "deviceSettingsDialog",
        fullscreen: _vm.$vuetify.breakpoint.xs,
        persistentOverlay: true,
        customCss: "device-settings",
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u(
        [
          _vm.showDefaultView
            ? {
                key: "actions",
                fn: function () {
                  return [
                    _c(
                      "v-btn",
                      {
                        staticClass: "black--text",
                        attrs: {
                          outlined: "",
                          text: "",
                          "data-cy": "deviceSettingsClose",
                        },
                        on: { click: _vm.close },
                      },
                      [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary white--text",
                        attrs: {
                          disabled: _vm.disableSave,
                          text: "",
                          "data-cy": "deviceSettingsSave",
                        },
                        on: { click: _vm.save },
                      },
                      [_vm._v(_vm._s(_vm.$t("buttons.save")))]
                    ),
                  ]
                },
                proxy: true,
              }
            : _vm.unregisterWarningOpen
            ? {
                key: "actions",
                fn: function () {
                  return [
                    _c("device-unregister-actions", {
                      attrs: { deviceId: _vm.deviceId },
                      on: {
                        unregister: _vm.handleUnregister,
                        close: function ($event) {
                          _vm.unregisterWarningOpen = false
                        },
                      },
                    }),
                  ]
                },
                proxy: true,
              }
            : _vm.zeroConfirmOpen
            ? {
                key: "actions",
                fn: function () {
                  return [
                    _c("device-zero-confirmation-actions", {
                      on: {
                        confirm: _vm.confirmAddZero,
                        cancel: function ($event) {
                          _vm.zeroConfirmOpen = false
                        },
                      },
                    }),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _vm.showDefaultView
        ? [
            _c("v-progress-circular", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              attrs: { indeterminate: "", color: "primary" },
            }),
            _c(
              "v-tabs",
              {
                attrs: { centered: "", "fixed-tabs": "", inert: _vm.isLoading },
                model: {
                  value: _vm.tab,
                  callback: function ($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab",
                },
              },
              [
                _c("v-tabs-slider", { attrs: { color: _vm.tabSliderColor } }),
                _c(
                  "v-tab",
                  {
                    class: { "error--text": !_vm.generalTab.valid },
                    attrs: { "data-cy": "generalTab", href: "#generalTab" },
                  },
                  [
                    _c("span", { staticClass: "tab-title" }, [
                      _vm._v(_vm._s(_vm.$t("map.deviceSettings.general"))),
                    ]),
                  ]
                ),
                _c(
                  "v-tab",
                  {
                    class: { "error--text": !_vm.locationTab.valid },
                    attrs: { "data-cy": "locationTab", href: "#locationTab" },
                  },
                  [
                    _c("span", { staticClass: "tab-title" }, [
                      _vm._v(_vm._s(_vm.$t("map.deviceSettings.location"))),
                    ]),
                  ]
                ),
                _vm.calibrationTab.show
                  ? _c(
                      "v-tab",
                      {
                        class: { "error--text": !_vm.calibrationTab.valid },
                        attrs: {
                          "data-cy": "calibrationTab",
                          href: "#calibrationTab",
                        },
                      },
                      [
                        _c("span", { staticClass: "tab-title" }, [
                          _vm._v(
                            _vm._s(_vm.$t("map.deviceSettings.calibration"))
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "v-tab-item",
                  { attrs: { eager: true, value: "generalTab" } },
                  [
                    _c(
                      "v-form",
                      {
                        ref: "generalTab",
                        model: {
                          value: _vm.generalTab.valid,
                          callback: function ($$v) {
                            _vm.$set(_vm.generalTab, "valid", $$v)
                          },
                          expression: "generalTab.valid",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          { attrs: { flat: "" } },
                          [
                            _c("device-name", {
                              attrs: {
                                rules: [_vm.rules.required],
                                cyTag: "settingsDeviceName",
                              },
                              model: {
                                value: _vm.generalTab.deviceName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.generalTab, "deviceName", $$v)
                                },
                                expression: "generalTab.deviceName",
                              },
                            }),
                            _c(
                              "v-card",
                              {
                                staticClass: "pa-0 ma-0",
                                attrs: {
                                  disabled: !_vm.generalTab.enablePublicToggle,
                                  elevation: "0",
                                  "data-cy": "settingsIsPublicContainer",
                                },
                              },
                              [
                                _c("v-switch", {
                                  attrs: {
                                    label: _vm.$t("activateDevice.isPublic"),
                                    color: "primary",
                                    hint: _vm.$t(
                                      "activateDevice.isPublicDescription"
                                    ),
                                    "persistent-hint": "",
                                    "data-cy": "settingsIsPublic",
                                  },
                                  model: {
                                    value: _vm.generalTab.isPublic,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.generalTab, "isPublic", $$v)
                                    },
                                    expression: "generalTab.isPublic",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.generalTab.showIndoorSensor
                              ? _c(
                                  "div",
                                  { staticClass: "secondary-indicator" },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        disabled: _vm.generalTab.isIndoorOnly,
                                        color: "primary",
                                        hint: _vm.indoorSensorHint,
                                        "persistent-hint":
                                          _vm.generalTab.isIndoorOnly,
                                        "data-cy": "settingsIsIndoor",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "activateDevice.indoorSensor"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "device-type-indicator",
                                                  },
                                                  [
                                                    _vm.generalTab.indoorSensor
                                                      ? _c("img", {
                                                          attrs: {
                                                            src: _vm.indoorPinSrc,
                                                            alt: "Indoor Device Pin",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    !_vm.generalTab.indoorSensor
                                                      ? _c("img", {
                                                          attrs: {
                                                            src: _vm.outdoorPinSrc,
                                                            alt: "Outdoor Device Pin",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3510025413
                                      ),
                                      model: {
                                        value: _vm.generalTab.indoorSensor,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.generalTab,
                                            "indoorSensor",
                                            $$v
                                          )
                                        },
                                        expression: "generalTab.indoorSensor",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.generalTab.showLoggingCleaningIntervals
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.loggingIntervalItems,
                                              label: _vm.$t(
                                                "map.deviceSettings.loggingInterval"
                                              ),
                                              "data-cy":
                                                "loggingIntervalDropDown",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-list-item",
                                                        _vm._g(
                                                          _vm._b(
                                                            {},
                                                            "v-list-item",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: "",
                                                                disabled:
                                                                  !item.disabled,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-list-item-content",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {},
                                                                                "v-list-item-content",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          "data-cy":
                                                                                            "" +
                                                                                            item.text,
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item.text
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "map.deviceSettings.upgrade"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2684946375
                                            ),
                                            model: {
                                              value: _vm.loggingInterval,
                                              callback: function ($$v) {
                                                _vm.loggingInterval = $$v
                                              },
                                              expression: "loggingInterval",
                                            },
                                          }),
                                        ],
                                      ],
                                      2
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.cleaningIntervalItems,
                                              "data-cy":
                                                "cleaningIntervalDropDown",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cleaning-interval-label-wrapper",
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "map.deviceSettings.cleaningInterval"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                                "max-width":
                                                                  "400",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "span",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      icon: "",
                                                                                    },
                                                                                },
                                                                                "span",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      "data-cy":
                                                                                        "cleaningIntervalTooltip",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-information-outline"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  1441692416
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "map.deviceSettings.cleaningIntervalToolTip"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "item",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-list-item",
                                                        _vm._g(
                                                          _vm._b(
                                                            {},
                                                            "v-list-item",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: "",
                                                                disabled:
                                                                  !item.disabled,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-list-item-content",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {},
                                                                                "v-list-item-content",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        item.text
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "map.deviceSettings.upgrade"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2869183531
                                            ),
                                            model: {
                                              value: _vm.cleaningInterval,
                                              callback: function ($$v) {
                                                _vm.cleaningInterval = $$v
                                              },
                                              expression: "cleaningInterval",
                                            },
                                          }),
                                        ],
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-btn",
                              {
                                staticClass: "error--text",
                                attrs: {
                                  text: "",
                                  "data-cy": "unregisterButton",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.unregisterWarningOpen = true
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("deviceUnregister.unregisterButton")
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "hint-text hint-text-button" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("deviceUnregister.unregisterHelp")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { attrs: { eager: true, value: "locationTab" } },
                  [
                    _c(
                      "v-form",
                      {
                        ref: "locationTab",
                        model: {
                          value: _vm.locationTab.valid,
                          callback: function ($$v) {
                            _vm.$set(_vm.locationTab, "valid", $$v)
                          },
                          expression: "locationTab.valid",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "tab-content__title" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("activateDevice.location")) +
                                    " "
                                ),
                              ]
                            ),
                            _c("device-location-name", {
                              attrs: { cyTag: "deviceSettingsLocationName" },
                              model: {
                                value: _vm.locationTab.locationName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.locationTab, "locationName", $$v)
                                },
                                expression: "locationTab.locationName",
                              },
                            }),
                            _c(
                              "v-card",
                              {
                                staticClass: "ma-0 pa-0",
                                attrs: {
                                  disabled: !this.locationTab.allowSetLocation,
                                  elevation: "0",
                                  "data-cy": "settingsSetLocationContainer",
                                },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "primary--text",
                                    attrs: {
                                      loading: _vm.gettingLocation,
                                      text: "",
                                      disabled: _vm.locationError,
                                      "data-cy": "useBrowserLocation",
                                    },
                                    on: { click: _vm.useBrowserLocation },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("activateDevice.useMyLocation")
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "hint-text hint-text-button" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "activateDevice.useMyLocationHint"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("br"),
                                _c("div", { staticClass: "errorMessage" }, [
                                  _vm._v(_vm._s(this.locationErrorMessage)),
                                ]),
                                _c("v-text-field", {
                                  class: {
                                    "text-field-required":
                                      this.locationTab.allowSetLocation,
                                  },
                                  attrs: {
                                    label: _vm.$t("activateDevice.latitude"),
                                    min: "-90",
                                    max: "90",
                                    maxlength: "12",
                                    rules: this.latitudeRules,
                                    "data-cy": "deviceSettingsLatitude",
                                    disabled:
                                      !this.locationTab.allowSetLocation,
                                  },
                                  on: {
                                    keypress: function ($event) {
                                      return _vm.preventNonCoords($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.locationTab.coords.latitude,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.locationTab.coords,
                                        "latitude",
                                        $$v
                                      )
                                    },
                                    expression: "locationTab.coords.latitude",
                                  },
                                }),
                                _c("v-text-field", {
                                  class: {
                                    "text-field-required":
                                      this.locationTab.allowSetLocation,
                                  },
                                  attrs: {
                                    label: _vm.$t("activateDevice.longitude"),
                                    "persistent-hint": "",
                                    min: "-180",
                                    max: "180",
                                    maxlength: "13",
                                    rules: this.longitudeRules,
                                    "data-cy": "deviceSettingsLongitude",
                                    disabled:
                                      !this.locationTab.allowSetLocation,
                                  },
                                  on: {
                                    keypress: function ($event) {
                                      return _vm.preventNonCoords($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.locationTab.coords.longitude,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.locationTab.coords,
                                        "longitude",
                                        $$v
                                      )
                                    },
                                    expression: "locationTab.coords.longitude",
                                  },
                                }),
                                _c("div", { staticClass: "hint-text" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("activateDevice.coordinateHint")
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "hint-text-link",
                                      attrs: {
                                        href: _vm.$t(
                                          "activateDevice.coordinateHintLink"
                                        ),
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "activateDevice.coordinateHintLink"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { attrs: { eager: true, value: "calibrationTab" } },
                  [
                    _c(
                      "v-form",
                      {
                        ref: "calibrationTab",
                        model: {
                          value: _vm.calibrationTab.valid,
                          callback: function ($$v) {
                            _vm.$set(_vm.calibrationTab, "valid", $$v)
                          },
                          expression: "calibrationTab.valid",
                        },
                      },
                      [
                        _c("device-calibration", {
                          attrs: {
                            deviceModel: _vm.generalTab.deviceModel,
                            userCals: _vm.calibrationTab.userCals,
                            userZeros: _vm.calibrationTab.userZeros,
                            permissions: _vm.permissions,
                          },
                          on: {
                            "update:userCals": function ($event) {
                              return _vm.$set(
                                _vm.calibrationTab,
                                "userCals",
                                $event
                              )
                            },
                            "update:user-cals": function ($event) {
                              return _vm.$set(
                                _vm.calibrationTab,
                                "userCals",
                                $event
                              )
                            },
                            "update:userZeros": function ($event) {
                              return _vm.$set(
                                _vm.calibrationTab,
                                "userZeros",
                                $event
                              )
                            },
                            "update:user-zeros": function ($event) {
                              return _vm.$set(
                                _vm.calibrationTab,
                                "userZeros",
                                $event
                              )
                            },
                            validated: _vm.onValidation,
                            requestZero: _vm.requestZero,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.unregisterWarningOpen
        ? _c(
            "span",
            [
              _c("device-unregister-card", {
                attrs: { deviceId: _vm.deviceId },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.zeroConfirmOpen
        ? _c(
            "span",
            [
              _c("device-zero-confirmation", {
                attrs: {
                  zeroValue: _vm.calibrationTab.queuedZeroRequest.zeroValue,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }