var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        error: _vm.error,
        title: _vm.$t("shareDevice.title"),
        persistentOverlay: true,
        "data-cy": "deviceSharingDialog",
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "v-card-title",
                [
                  _c("v-icon", { staticClass: "header-icon" }, [
                    _vm._v("mdi-share-variant"),
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("shareDevice.title")) + " "),
                ],
                1
              ),
              _vm.showHintText
                ? _c("v-card-subtitle", { staticClass: "hint-text" }, [
                    _vm._v(" " + _vm._s(_vm.maximumNumberCollaborators) + " "),
                  ])
                : _vm._e(),
            ]
          },
          proxy: true,
        },
        {
          key: "actions",
          fn: function () {
            return [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary white--text large",
                  attrs: { text: "", "data-cy": "deviceSharingClose" },
                  on: { click: _vm.close },
                },
                [_vm._v(_vm._s(_vm.$t("buttons.done")))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("div", { staticClass: "hint-text" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("shareDevice.ensureAccountInstruction")) + " "
            ),
          ]),
          _c(
            "v-row",
            {
              class: { disabled: _vm.addDisabled },
              attrs: { align: "center" },
            },
            [
              _c(
                "div",
                { staticClass: "collaborator-email-wrapper" },
                [
                  _c("v-text-field", {
                    staticClass: "collaborator-email",
                    attrs: {
                      label: _vm.$t("shareDevice.emailLabel"),
                      rules: [_vm.rules.email],
                      "data-cy": "collaboratorEnterEmail",
                    },
                    model: {
                      value: _vm.newCollaboratorEmail,
                      callback: function ($$v) {
                        _vm.newCollaboratorEmail = $$v
                      },
                      expression: "newCollaboratorEmail",
                    },
                  }),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-tooltip",
                {
                  attrs: { disabled: !_vm.showUpgradeTooltip, bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "div",
                            _vm._g({}, on),
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-add",
                                  class: { disabled: _vm.addDisabled },
                                  attrs: {
                                    text: "",
                                    disabled: _vm.addDisabled,
                                    "data-cy": "addCollaboratorButton",
                                  },
                                  on: { click: _vm.addCollaborator },
                                },
                                [_vm._v(_vm._s(_vm.$t("buttons.add")))]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("shareDevice.upgradeTooltip"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.collaborators.length > 0
        ? _c("v-simple-table", {
            staticClass: "collabTable",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("thead", [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticClass: "text-left",
                              attrs: { scope: "col" },
                            },
                            [_vm._v(_vm._s(_vm.$t("shareDevice.email")))]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-left",
                              attrs: { scope: "col" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("shareDevice.status")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticClass: "text-left",
                              attrs: { scope: "col" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("shareDevice.actions")) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.collaborators, function (collaborator) {
                          return _c(
                            "tr",
                            {
                              key: collaborator.email,
                              attrs: { "data-cy": "sharedEmail" },
                            },
                            [
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.trim(collaborator.email, 24))
                                ),
                              ]),
                              _c(
                                "td",
                                { attrs: { "data-cy": "sharedStatus" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        title:
                                          collaborator.status == "active"
                                            ? "Active"
                                            : "No Account",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            collaborator.status == "active"
                                              ? "mdi-check"
                                              : "mdi-alert"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "td",
                                { staticClass: "td-actions" },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: { "offset-y": "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "black--text actions-button",
                                                      attrs: {
                                                        text: "",
                                                        "data-cy":
                                                          "sharedActions",
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-dots-vertical"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: {
                                                "data-cy": "deleteCollaborator",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeCollaborator(
                                                    collaborator
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-delete"),
                                              ]),
                                              _c("v-list-item-title", [
                                                _vm._v("Delete"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3163455407
            ),
          })
        : _c("div", { staticClass: "notShared hint-text" }, [
            _vm._v(" " + _vm._s(_vm.$t("shareDevice.notShared")) + " "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }