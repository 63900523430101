var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "one-measurement-wrapper" },
    [
      _c("popover-measurement", {
        attrs: {
          reading: _vm.currentMeasurement.reading,
          label: _vm.currentMeasurement.label,
          unitsLabel: _vm.currentMeasurement.unitsLabel,
          imageSrc: _vm.currentMeasurement.imageSrc,
          showInactiveReading: _vm.showInactiveReadings,
          showRealValue: true,
          customClass: _vm.size + "-size bolded-reading grey-empty-reading",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }