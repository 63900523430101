var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "ellipsis",
                        attrs: {
                          text: "",
                          "data-cy": "accountPopoverDropdown",
                        },
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                {
                  attrs: {
                    disabled: !_vm.hasLogInAsPermission,
                    link: "",
                    "data-cy": "logInAsActionItem",
                  },
                  on: { click: _vm.openLogInAs },
                },
                [
                  _c("v-icon", [_vm._v("mdi-arrow-up-bold-box-outline")]),
                  _c("v-list-item-title", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("accountTable.actionMenu.logInAs")) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }