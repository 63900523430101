var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tbody",
    _vm._l(_vm.items, function (item) {
      return _c("tr", { key: item.id }, [
        _c("td", { staticClass: "mobile-list" }, [
          _c(
            "div",
            { staticClass: "item-title" },
            [
              _c("account-action-menu", {
                attrs: {
                  accountId: item.accountId,
                  userId: item.userId,
                  sapId: item.sapAcctId,
                  email: item.email,
                },
              }),
              _c("div", { staticClass: "account-email" }, [
                _c("span", [_vm._v(_vm._s(item.email))]),
              ]),
            ],
            1
          ),
          _c("ul", { staticClass: "flex-content" }, [
            _c("li", { staticClass: "flex-item" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("accountTable.tableHeaders.username")) +
                    ":  "
                ),
              ]),
              _vm._v(" " + _vm._s(item.username) + " "),
            ]),
            _c("li", { staticClass: "flex-item" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("accountTable.tableHeaders.accountID")) +
                    ":  "
                ),
              ]),
              _vm._v(" " + _vm._s(item.accountId) + " "),
            ]),
            _c("li", { staticClass: "flex-item" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("accountTable.tableHeaders.userSubscription")
                    ) +
                    ":  "
                ),
              ]),
              _vm._v(" " + _vm._s(item.subscription) + " "),
            ]),
            _c("li", { staticClass: "flex-item" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("accountTable.tableHeaders.sapAcctId")) +
                    ":  "
                ),
              ]),
              _vm._v(" " + _vm._s(item.sapAcctId) + " "),
            ]),
            _c("li", { staticClass: "flex-item" }, [
              item.lastLogIn == 0
                ? _c("div", { staticClass: "invalid-date" }, [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("accountTable.tableHeaders.logins")) +
                          ":  "
                      ),
                    ]),
                  ])
                : _c("div", { staticClass: "valid-date" }, [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("accountTable.tableHeaders.logins")) +
                          ":  "
                      ),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(new Date(item.lastLogIn).toLocaleString()) +
                        " "
                    ),
                  ]),
            ]),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }