var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.componentName, {
    tag: "component",
    attrs: {
      "v-if": _vm.componentName,
      widgetId: _vm.widgetId,
      widgetType: _vm.widgetType,
      widgetMaxDevices: _vm.widgetMaxDevices,
      deviceIds: _vm.deviceIds,
      width: _vm.width,
      height: _vm.height,
      settings: _vm.settings,
      isDialog: _vm.isDialog,
    },
    on: {
      close: _vm.close,
      removeWidget: _vm.removeWidget,
      refreshDashboard: _vm.refreshDashboard,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }