var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-dialog",
        {
          attrs: {
            open: _vm.open,
            persistentOverlay: true,
            "max-width": 550,
            customCss: "register-multiple-devices " + _vm.step,
          },
          on: { close: _vm.close },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("v-card-title", [
                    _vm._v(" " + _vm._s(_vm.dialogTitle) + " "),
                  ]),
                  _c("v-card-subtitle", [
                    _vm._v(" " + _vm._s(_vm.dialogSubTitle) + " "),
                    _vm.downloadCsv
                      ? _c(
                          "a",
                          {
                            staticClass: "download-link",
                            attrs: {
                              href: "csv/Bulk device registration import template.csv",
                              "aria-label": _vm.$t(
                                "registerMultipleDevices.downloadCsvFileAriaLabel"
                              ),
                              download: "",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "registerMultipleDevices.downloadCsvFileText"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  !_vm.removeCancelButton
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "black--text",
                          attrs: {
                            "data-cy": "registerMultipleClose",
                            outlined: "",
                            text: "",
                          },
                          on: { click: _vm.close },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary white--text",
                      attrs: {
                        "data-cy": "registerMultipleActionButton",
                        text: "",
                        disabled: _vm.disableActionButton,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onClickActionButton()
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "trailing-icon-text" }, [
                        _vm._v(_vm._s(_vm.dialogActionButtonText)),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("dialog-content", {
            attrs: { cssStyle: _vm.cssStyle },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [_vm._t("content")]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }