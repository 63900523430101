var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dialogs.info
        ? _c("device-info-dialog", {
            attrs: { deviceId: _vm.deviceId, open: _vm.dialogs.info },
            on: {
              close: function ($event) {
                return _vm.closeDialog("info")
              },
            },
          })
        : _vm._e(),
      _vm.dialogs.settings
        ? _c("device-settings-dialog", {
            attrs: {
              deviceId: _vm.deviceId,
              permissions: _vm.permissions,
              open: _vm.dialogs.settings,
            },
            on: {
              close: function ($event) {
                return _vm.closeDialog("settings")
              },
            },
          })
        : _vm._e(),
      _vm.dialogs.alerts
        ? _c("device-alerts-dialog", {
            attrs: {
              deviceId: _vm.deviceId,
              permissions: _vm.permissions,
              open: _vm.dialogs.alerts,
            },
            on: {
              close: function ($event) {
                return _vm.closeDialog("alerts")
              },
            },
          })
        : _vm._e(),
      _vm.dialogs.sharing
        ? _c("device-sharing-dialog", {
            attrs: {
              deviceId: _vm.deviceId,
              permissions: _vm.permissions,
              open: _vm.dialogs.sharing,
            },
            on: {
              close: function ($event) {
                return _vm.closeDialog("sharing")
              },
            },
          })
        : _vm._e(),
      _vm.dialogs.history
        ? _c("history-dialog", {
            attrs: {
              currentDeviceId: _vm.deviceId,
              isDetachedDialog: false,
              removableDevices: false,
              open: _vm.dialogs.history,
            },
            on: {
              close: function ($event) {
                return _vm.closeDialog("history")
              },
            },
          })
        : _vm._e(),
      _vm.dialogs.currentReadings
        ? _c("current-readings-dialog", {
            attrs: {
              currentDeviceId: _vm.deviceId,
              open: _vm.dialogs.currentReadings,
            },
            on: {
              close: function ($event) {
                return _vm.closeDialog("currentReadings")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }