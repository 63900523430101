var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subscriptions-cancel-actions-row" },
    [
      _vm.isCancelling === false
        ? _c(
            "v-btn",
            {
              staticClass: "black--text",
              attrs: {
                outlined: "",
                "data-cy": "cancelSubscriptionCloseButton",
                text: "",
              },
              on: { click: _vm.close },
            },
            [_vm._v(_vm._s(_vm.$t("buttons.close")))]
          )
        : _vm._e(),
      _c("v-spacer"),
      _vm.isCancelling
        ? _c(
            "div",
            {
              staticClass: "text-center emptyMessage",
              attrs: { id: "progressSpinner" },
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" },
              }),
            ],
            1
          )
        : _c(
            "v-btn",
            {
              staticClass: "error white--text",
              attrs: { text: "", "data-cy": "cancelSubscriptionConfirmButton" },
              on: { click: _vm.cancelSubscriptionConfirm },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("subscriptionCancel.cancelCard.actionButton"))
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }