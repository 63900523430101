var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "measurement-container" },
    [
      _vm.showSmallMeasurements
        ? _c(
            "measurements-small",
            _vm._b({}, "measurements-small", _vm.$props, false)
          )
        : _c(
            "measurements-large",
            _vm._b({}, "measurements-large", _vm.$props, false)
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }