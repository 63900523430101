var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "in-progress-message" }, [
    _c(
      "div",
      [
        _c("v-progress-circular", {
          attrs: { indeterminate: "", color: "primary" },
        }),
      ],
      1
    ),
    _vm.messages && _vm.messages.length
      ? _c(
          "div",
          _vm._l(_vm.messages, function (message, index) {
            return _c("p", { key: index }, [_vm._v(_vm._s(message))])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }