var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "api-container" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("v-card", [
                _vm.hasUserPermission("ExternalApiV3ApigeeButton")
                  ? _c(
                      "span",
                      [
                        _c(
                          "v-card-title",
                          { attrs: { "data-cy": "api-secret-subtitle" } },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("apiServices.titleDeveloperPortal"))
                            ),
                          ]
                        ),
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "ma-2",
                                attrs: { "data-cy": "open-portal-button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openPortalLink()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("apiServices.buttonOpenPortal")
                                    ) +
                                    " "
                                ),
                                _c("v-icon", { attrs: { right: "" } }, [
                                  _vm._v("mdi-open-in-new"),
                                ]),
                              ],
                              1
                            ),
                            _c("v-spacer"),
                            _c(
                              "label",
                              {
                                staticClass: "ma-2",
                                attrs: { "data-cy": "api-portal-description" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("apiServices.portalDescription")
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("accountSubscriptions.dataServicesSubscriptions")
                      )
                    ),
                  ]),
                  _vm.removeLock || _vm.subLock
                    ? _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("v-progress-circular", {
                            staticClass: "ma-4",
                            attrs: { indeterminate: "", color: "primary" },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "v-list",
                        {
                          staticClass: "inline",
                          attrs: { subheader: "", dense: "" },
                        },
                        _vm._l(_vm.dataSubscriptions, function (sub, i) {
                          return _c(
                            "v-list-item",
                            { key: i },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(sub.name)),
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "accountSubscriptions.deviceAllowance"
                                          )
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.$t(
                                            "accountSubscriptions.deviceSubscriptionsUsedRemaining",
                                            {
                                              qty: sub.totalUsed,
                                              remaining: sub.totalQtyRemaining,
                                            }
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("div", [
                _vm._v(_vm._s(_vm.$t("apiServices.registeredDevices"))),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "black--text register-btn",
                  attrs: {
                    text: "",
                    "data-cy": "registerDevicesDialogButton",
                    disabled: _vm.removeLock || _vm.subLock,
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialogs.registerDevicesDialog.open = true
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "header-icon" }, [
                    _vm._v("mdi-map-marker-plus"),
                  ]),
                  _vm._v(
                    " " + _vm._s(_vm.$t("apiServices.registerDevices")) + " "
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "registered-devices-card" },
            [
              !_vm.removeLock && !_vm.subLock
                ? _c("v-data-table", {
                    class: _vm.registeredDevicesClass,
                    attrs: {
                      headers: _vm.registeredDevices.headers,
                      "hide-default-header": _vm.isMobile,
                      items: _vm.devices,
                      "disable-sort": true,
                      "mobile-breakpoint":
                        _vm.$vuetify.breakpoint.thresholds.sm,
                      "footer-props": _vm.registeredDevicesFooterProps,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "body",
                          fn: function (ref) {
                            var items = ref.items
                            return [
                              !_vm.isMobile
                                ? _c(
                                    "tbody",
                                    {
                                      staticClass: "registered-device-table",
                                      attrs: {
                                        "data-updated": _vm.updateForced,
                                      },
                                    },
                                    _vm._l(items, function (item) {
                                      return _c("tr", { key: item.id }, [
                                        _c(
                                          "td",
                                          { staticClass: "black--text" },
                                          [_vm._v(_vm._s(item.serial))]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "max-width": "300px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  bottom: "",
                                                  "max-width": 600,
                                                  disabled:
                                                    !_vm.isNameOverflowing[
                                                      item.id
                                                    ],
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function (ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "div",
                                                            _vm._g(
                                                              {
                                                                ref: item.id,
                                                                refInFor: true,
                                                                staticClass:
                                                                  "device-name",
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.deviceName
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(item.deviceName)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          _c("span", [
                                            _vm._v(_vm._s(item.dataServices)),
                                          ]),
                                        ]),
                                        _c(
                                          "td",
                                          [
                                            _vm.deviceHasWebsiteSubscription(
                                              item
                                            )
                                              ? [
                                                  item.websiteSubscription
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.websiteSubscription
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  item.freeTier
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "apiServices.table.freeTierName"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              : _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "apiServices.table.notRegistered"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "text-xs-left right-aligned",
                                          },
                                          [
                                            _c("action-menu", {
                                              attrs: {
                                                deviceId: item.id,
                                                websiteSubscription:
                                                  item.websiteSubscription,
                                                hasFreeTier: item.freeTier,
                                                removeLock: _vm.removeLock,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ])
                                    }),
                                    0
                                  )
                                : _c(
                                    "tbody",
                                    {
                                      staticClass:
                                        "registered-device-mobile-table",
                                      attrs: {
                                        "data-updated": _vm.updateForced,
                                      },
                                    },
                                    _vm._l(items, function (item) {
                                      return _c("tr", { key: item.id }, [
                                        _c(
                                          "td",
                                          { staticClass: "mobile-list" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "item-title" },
                                              [
                                                _c("action-menu", {
                                                  attrs: {
                                                    deviceId: item.id,
                                                    websiteSubscription:
                                                      item.websiteSubscription,
                                                    hasFreeTier: item.freeTier,
                                                    removeLock: _vm.removeLock,
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  { staticClass: "title-text" },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.serial) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              { attrs: { "no-gutters": "" } },
                                              [
                                                _c("v-col", [
                                                  _c("ul", {}, [
                                                    item.deviceName
                                                      ? _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "list-item",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "mobile-header",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "apiServices.table.deviceName"
                                                                      )
                                                                    ) +
                                                                    ": "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                  "max-width": 600,
                                                                  disabled:
                                                                    !_vm
                                                                      .isNameOverflowing[
                                                                      item.id
                                                                    ],
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              _vm._g(
                                                                                {
                                                                                  ref: item.id,
                                                                                  refInFor: true,
                                                                                  staticClass:
                                                                                    "mobile-field device-name",
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        item.deviceName
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.deviceName
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "list-item",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mobile-header",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "apiServices.table.dataServices"
                                                                  )
                                                                ) +
                                                                ": "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mobile-field",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.dataServices
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "list-item",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mobile-header",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "apiServices.table.websiteSubs"
                                                                  )
                                                                ) +
                                                                ": "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.deviceHasWebsiteSubscription(
                                                          item
                                                        )
                                                          ? [
                                                              item.websiteSubscription
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "mobile-field",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.websiteSubscription
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "mobile-field",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "apiServices.table.freeTierName"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                            ]
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "mobile-field",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "apiServices.table.notRegistered"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      2
                                                    ),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2436948064
                    ),
                  })
                : _c(
                    "div",
                    { staticClass: "text-center emptyMessage" },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c("register-devices-dialog", {
        attrs: {
          open: _vm.dialogs.registerDevicesDialog.open,
          removeLock: _vm.removeLock,
          subLock: _vm.subLock,
        },
        on: {
          close: _vm.registerDevicesDialogClose,
          getUserDevices: _vm.getUserDevices,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }