var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isEnableNotificationSetting
        ? _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "padding pa-4",
                          staticStyle: {
                            "padding-top": "0px !important",
                            "padding-bottom": "0px !important",
                          },
                          attrs: { flat: "" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("v-text-field", {
                                staticClass: "text-field-required",
                                attrs: {
                                  label: _vm.$t(
                                    "map.deviceAlerts.fields.title"
                                  ),
                                  maxlength: "20",
                                  counter: "20",
                                  disabled: _vm.editing,
                                  rules: [_vm.rules.required],
                                  "data-cy": "addTitle",
                                },
                                model: {
                                  value: _vm.currentAlert.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.currentAlert, "title", $$v)
                                  },
                                  expression: "currentAlert.title",
                                },
                              }),
                              _c("v-select", {
                                staticClass: "text-field-required",
                                attrs: {
                                  items: _vm.measurements,
                                  "item-text": "text",
                                  "item-value": "value",
                                  disabled: _vm.editing,
                                  label: _vm.$t(
                                    "map.deviceAlerts.fields.sensor"
                                  ),
                                  "data-cy": "addSensor",
                                  rules: [_vm.rules.required],
                                },
                                on: { change: _vm.validateThreshold },
                                model: {
                                  value: _vm.currentAlert.condition.field,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentAlert.condition,
                                      "field",
                                      $$v
                                    )
                                  },
                                  expression: "currentAlert.condition.field",
                                },
                              }),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.operators,
                                  "item-text": "text",
                                  "item-value": "value",
                                  disabled: _vm.editing,
                                  label: _vm.$t(
                                    "map.deviceAlerts.fields.condition"
                                  ),
                                  "data-cy": "addCondition",
                                },
                                on: { change: _vm.validateThreshold },
                                model: {
                                  value: _vm.currentAlert.condition.operator,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentAlert.condition,
                                      "operator",
                                      $$v
                                    )
                                  },
                                  expression: "currentAlert.condition.operator",
                                },
                              }),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.frequencyType,
                                  "item-text": "text",
                                  "item-value": "value",
                                  label: _vm.$t(
                                    "map.deviceAlerts.fields.frequency"
                                  ),
                                  "data-cy": "frequency",
                                },
                                model: {
                                  value: _vm.currentAlert.frequencyType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentAlert,
                                      "frequencyType",
                                      $$v
                                    )
                                  },
                                  expression: "currentAlert.frequencyType",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-4",
                          staticStyle: {
                            "padding-top": "0px !important",
                            "padding-bottom": "0px !important",
                          },
                          attrs: { flat: "" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: _vm.$t(
                                    "map.deviceAlerts.fields.enabled"
                                  ),
                                  color: "primary",
                                  "data-cy": "addEnabled",
                                },
                                model: {
                                  value: _vm.currentAlert.enabled,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.currentAlert, "enabled", $$v)
                                  },
                                  expression: "currentAlert.enabled",
                                },
                              }),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.levels,
                                  "item-text": "text",
                                  "item-value": "value",
                                  label: _vm.$t(
                                    "map.deviceAlerts.fields.level"
                                  ),
                                  "data-cy": "addLevel",
                                },
                                model: {
                                  value: _vm.currentAlert.severity,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.currentAlert, "severity", $$v)
                                  },
                                  expression: "currentAlert.severity",
                                },
                              }),
                              _c("v-text-field", {
                                ref: "threshold",
                                staticClass: "text-field-required",
                                attrs: {
                                  label: _vm.$t(
                                    "map.deviceAlerts.fields.value"
                                  ),
                                  maxlength: "10",
                                  disabled: _vm.measurementSet,
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.validNum,
                                    _vm.rules.threshold,
                                    _vm.rules.precision,
                                  ],
                                  "data-cy": "addThreshold",
                                },
                                on: {
                                  keypress: function ($event) {
                                    return _vm.preventNonNumbers($event)
                                  },
                                },
                                model: {
                                  value: _vm.currentAlert.condition.value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentAlert.condition,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression: "currentAlert.condition.value",
                                },
                              }),
                              _c("transition", [
                                _vm.helpText
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "help-text",
                                        attrs: {
                                          "data-cy": "thresholdHelpText",
                                        },
                                      },
                                      [_c("p", [_vm._v(_vm._s(_vm.helpText))])]
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { staticClass: "pa-4 pt-0" },
                    [
                      _vm.isNotificationEnabled
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "map.deviceAlerts.enableNotificationsHelp"
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 pl-4 send-notification",
                              attrs: { cols: "12", sm: "6" },
                            },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  color: "primary",
                                  label: _vm.$t(
                                    "map.deviceAlerts.fields.sendNotifications"
                                  ),
                                  disabled:
                                    (_vm.isNotificationEnabled &&
                                      !_vm.currentAlert.sendEmail &&
                                      !_vm.currentAlert.sendSms) ||
                                    _vm.enableNotificationBtn,
                                },
                                on: { change: _vm.removeSelection },
                                model: {
                                  value: _vm.isSendNotification,
                                  callback: function ($$v) {
                                    _vm.isSendNotification = $$v
                                  },
                                  expression: "isSendNotification",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "enable-notification",
                              attrs: { cols: "12", sm: "6" },
                            },
                            [
                              _vm.enableNotificationBtn
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "primary--text mobile-menu",
                                      attrs: { text: "" },
                                      on: { click: _vm.toggleNotification },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "map.deviceAlerts.fields.enableNotifications"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isSendNotification
                        ? _c(
                            "div",
                            [
                              _c("v-label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("map.deviceAlerts.fields.deliverTo")
                                  )
                                ),
                              ]),
                              _c("v-checkbox", {
                                staticClass: "zero-height-v-messages",
                                attrs: {
                                  color: "primary",
                                  label: _vm.getSmsLabel,
                                  disabled: _vm.disableSms,
                                },
                                model: {
                                  value: _vm.currentAlert.sendSms,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.currentAlert, "sendSms", $$v)
                                  },
                                  expression: "currentAlert.sendSms",
                                },
                              }),
                              !_vm.enableNotificationBtn && _vm.disableSms
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "primary--text mobile-menu",
                                          attrs: { text: "" },
                                          on: { click: _vm.toggleNotification },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "map.deviceAlerts.fields.enableNotifications"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("v-checkbox", {
                                staticClass: "zero-height-v-messages",
                                attrs: {
                                  color: "primary",
                                  label: _vm.getEmailLabel,
                                  disabled: _vm.disableEmail,
                                },
                                model: {
                                  value: _vm.currentAlert.sendEmail,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.currentAlert, "sendEmail", $$v)
                                  },
                                  expression: "currentAlert.sendEmail",
                                },
                              }),
                              !_vm.enableNotificationBtn && _vm.disableEmail
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "primary--text mobile-menu",
                                          attrs: { text: "" },
                                          on: { click: _vm.toggleNotification },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "map.deviceAlerts.fields.enableNotifications"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-card", { staticClass: "pa-4", attrs: { flat: "" } }, [
                _c(
                  "div",
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.$t("map.deviceAlerts.fields.message"),
                        maxlength: "25",
                        counter: "25",
                        "data-cy": "addCustomMessage",
                      },
                      model: {
                        value: _vm.currentAlert.userMessage,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentAlert, "userMessage", $$v)
                        },
                        expression: "currentAlert.userMessage",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("v-card-actions", { attrs: { text: "" } }, [
                _c("div", { staticClass: "hint-text" }, [
                  _vm._v(" " + _vm._s(_vm.$t("validation.requiredHelp")) + " "),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.isEnableNotificationSetting
        ? _c("fusion-notification-settings", {
            attrs: { isSmallDisclaimerTitle: true, showMobileDoneButton: true },
            on: {
              onSave: _vm.onNotificationSelect,
              close: _vm.toggleNotification,
              onDisclaimerAgree: _vm.onDisclaimerAgree,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }