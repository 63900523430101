var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("register-multiple-devices-dialog", {
        attrs: {
          open: _vm.open,
          step: _vm.step,
          title: _vm.$t(_vm.currentStepConfiguration.overrideTitle),
          subTitle: _vm.$t(_vm.currentStepConfiguration.overrideSubtitle),
          downloadCsv: _vm.currentStepConfiguration.downloadCsv,
          disableActionButton: _vm.currentStepConfiguration.disableActionButton,
          onClickActionButton: _vm.actionHandler,
          actionButtonText: _vm.$t(
            _vm.currentStepConfiguration.overrideActionButtonText
          ),
          cssStyle: _vm.currentStepConfiguration.cssStyle,
          removeCancelButton: _vm.currentStepConfiguration.removeCancelButton,
        },
        on: { close: _vm.onCloseHandler },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _vm.currentStepConfiguration.process ===
                _vm.stepProcesses.UPLOAD
                  ? _c(
                      "v-form",
                      [
                        _c("file-csv-input", {
                          attrs: { value: _vm.csvFile },
                          on: { onFileChanged: _vm.setCsvFile },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.currentStepConfiguration.process ===
                _vm.stepProcesses.PROGRESS
                  ? _c("in-progress-message", {
                      attrs: { messages: _vm.message },
                    })
                  : _vm._e(),
                _vm.currentStepConfiguration.process ===
                _vm.stepProcesses.BEFORE_REGISTER
                  ? _c("success-message", {
                      attrs: {
                        message: _vm.$t(
                          "registerMultipleDevices.uploadSucceeded"
                        ),
                        fileName: _vm.csvFile.name,
                      },
                    })
                  : _vm._e(),
                _vm.currentStepConfiguration.process ===
                _vm.stepProcesses.FAILED
                  ? _c("error-message", { attrs: { errors: _vm.csvErrors } })
                  : _vm._e(),
                _vm.currentStepConfiguration.process ===
                _vm.stepProcesses.AFTER_REGISTER
                  ? _c("summary-message", {
                      attrs: {
                        errors: _vm.registrationErrors,
                        registeredDevices: _vm.registeredDevices,
                        failedDevices: _vm.failedDevices,
                        notProcessedDevices: _vm.notProcessedDevices,
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }