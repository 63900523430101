var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      class: _vm.addDeviceOptionsClass,
      attrs: { "max-width": 200 },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-list-group",
            {
              attrs: { "prepend-icon": _vm.titleIcon },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function () {
                    return [
                      _c("v-list-item-title", [_vm._v(_vm._s(_vm.titleText))]),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.expandAddDeviceOptions,
                callback: function ($$v) {
                  _vm.expandAddDeviceOptions = $$v
                },
                expression: "expandAddDeviceOptions",
              },
            },
            _vm._l(_vm.options, function (item, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  on: {
                    click: function ($event) {
                      return _vm.selectOption(item.action)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v(_vm._s(item.text))])],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }