var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.canAccess(_vm.access.FULL, _vm.accessLevel)
        ? _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "menu-dropdown black--text",
                              attrs: {
                                text: "",
                                "data-cy": "devicePopoverDropdown",
                              },
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2459160686
              ),
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "", "data-cy": "deviceInfoActivator" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.openDeviceInformationDialog.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-information")]),
                      _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("map.popover.menu.deviceInfo")) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "", "data-cy": "deviceSettingsActivator" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.openDeviceSettingsDialog.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-settings-box")]),
                      _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("map.popover.menu.deviceSettings")) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "", "data-cy": "deviceAlertsActivator" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.openDeviceAlertsDialog.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-bell")]),
                      _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("map.popover.menu.deviceAlerts")) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { "data-cy": "movePin" },
                      on: { click: _vm.startMovePin },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-cursor-move")]),
                      _c("v-list-item-title", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("map.popover.menu.movePin")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "", "data-cy": "deviceSharing" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.openDeviceSharingDialog.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-share-variant")]),
                      _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("map.popover.menu.deviceSharing")) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("device-info-dialog", {
        attrs: { open: _vm.dialogs.info, deviceId: _vm.deviceId },
        on: { close: _vm.closeDeviceInformationDialog },
      }),
      _c("device-settings-dialog", {
        attrs: {
          open: _vm.dialogs.settings,
          deviceId: _vm.deviceId,
          permissions: _vm.permissions,
        },
        on: {
          close: _vm.closeDeviceSettingsDialog,
          unregister: _vm.handleUnregister,
        },
      }),
      _c("device-alerts-dialog", {
        attrs: {
          open: _vm.dialogs.alerts,
          deviceId: _vm.deviceId,
          permissions: _vm.permissions,
        },
        on: { close: _vm.closeDeviceAlertsDialog },
      }),
      _c("device-sharing-dialog", {
        attrs: {
          open: _vm.dialogs.sharing,
          deviceId: _vm.deviceId,
          permissions: _vm.permissions,
        },
        on: { close: _vm.closeDeviceSharingDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }