var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "icon-message-wrapper" },
    [
      _c("v-icon", { attrs: { color: _vm.color } }, [
        _vm._v(" " + _vm._s(_vm.icon) + " "),
      ]),
      _c("span", [_vm._v(" " + _vm._s(_vm.message) + " ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }