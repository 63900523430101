var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "averages" },
    [
      _c("v-row", [
        _c("div", { staticClass: "label" }, [
          _vm._v(_vm._s(_vm.$t("map.popover.average"))),
        ]),
      ]),
      _c(
        "v-row",
        [
          _c("popover-average", {
            staticClass: "hide-xxs",
            attrs: {
              "data-cy": "pocHalfHour",
              value: _vm.averages.halfHour,
              name: "halfHour",
            },
          }),
          _c("popover-average", {
            attrs: {
              "data-cy": "pocOneHour",
              value: _vm.averages.oneHour,
              name: "oneHour",
            },
          }),
          _c("popover-average", {
            staticClass: "hide-xs",
            attrs: {
              "data-cy": "pocSixHour",
              value: _vm.averages.sixHour,
              name: "sixHour",
            },
          }),
          _c("popover-average", {
            attrs: {
              "data-cy": "pocTwentyFourHour",
              value: _vm.averages.oneDay,
              name: "oneDay",
            },
          }),
          _c("popover-average", {
            attrs: {
              "data-cy": "pocOneWeek",
              value: _vm.averages.oneWeek,
              name: "oneWeek",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }