var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "expandable-content-wrapper" },
    [
      _vm.showContent
        ? _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.item.devices,
              "hide-default-footer": "",
              "disable-pagination": "",
              "mobile-breakpoint": 0,
            },
          })
        : _vm._e(),
      _vm.showMessage
        ? _c("p", [
            _vm._v(
              _vm._s(
                _vm.$t("manageSubscriptions.sharedSubscriptionDevicesMessage")
              )
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }