var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.handleResize,
          expression: "handleResize",
        },
      ],
      key: _vm.item.id,
      class: { "child-expanded-row": _vm.isChild },
      attrs: { "data-cy": "device" + _vm.item.deviceSerial + "Row" },
    },
    [
      _c(
        "td",
        { staticClass: "text-start", attrs: { "data-cy": "columnExpand" } },
        [
          _vm.itemCanBeExpanded
            ? _c(
                "v-btn",
                {
                  staticClass: "v-data-table__expand-icon",
                  class: {
                    "v-data-table__expand-icon--active": _vm.isExpanded,
                  },
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.expand(!_vm.isExpanded)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.item.deviceName
        ? _c(
            "td",
            {
              staticStyle: { "max-width": "300px" },
              attrs: { "data-cy": "columnName" },
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    bottom: "",
                    "max-width": 600,
                    disabled: !_vm.isNameOverflowing,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              "div",
                              _vm._g(
                                {
                                  ref: _vm.item.id + "Name",
                                  staticClass: "device-name",
                                },
                                on
                              ),
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.item.deviceName)),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3274992340
                  ),
                },
                [_c("span", [_vm._v(_vm._s(_vm.item.deviceName))])]
              ),
            ],
            1
          )
        : _c("td", [_vm._v(_vm._s(_vm.$t("manageDevices.unnamedDevice")))]),
      _c(
        "td",
        {
          staticStyle: { "max-width": "300px" },
          attrs: { "data-cy": "columnLocationName" },
        },
        [
          _vm.item.deviceLocationName
            ? _c(
                "v-tooltip",
                {
                  attrs: {
                    bottom: "",
                    "max-width": 600,
                    disabled: !_vm.isLocationNameOverflowing,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              "div",
                              _vm._g(
                                {
                                  ref: _vm.item.id + "LocationName",
                                  staticClass: "device-name",
                                },
                                on
                              ),
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.item.deviceLocationName)),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2356591508
                  ),
                },
                [_c("span", [_vm._v(_vm._s(_vm.item.deviceLocationName))])]
              )
            : _c("span", { staticClass: "not-available-text" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("manageDevices.notAvailable")) + " "
                ),
              ]),
        ],
        1
      ),
      _c("td", { attrs: { "data-cy": "columnDevice" } }, [
        _vm._v(" " + _vm._s(_vm.item.deviceType) + " "),
      ]),
      _c(
        "td",
        {
          staticStyle: { "max-width": "120px" },
          attrs: { "data-cy": "columnModel" },
        },
        [_vm._v(" " + _vm._s(_vm.item.deviceModel) + " ")]
      ),
      _c("td", { attrs: { "data-cy": "columnSerial" } }, [
        _vm._v(" " + _vm._s(_vm.item.deviceSerial) + " "),
      ]),
      _c(
        "td",
        {
          class: {
            "not-available-text":
              !_vm.item.showSubscription ||
              !_vm.canAccess(_vm.access.FULL, _vm.item.accessCode),
          },
          attrs: { "data-cy": "columnSubscription" },
        },
        [_vm._v(" " + _vm._s(_vm.item.deviceSubscription) + " ")]
      ),
      _c("td", { attrs: { "data-cy": "columnAccess" } }, [
        _vm._v(" " + _vm._s(_vm.item.access) + " "),
      ]),
      _c(
        "td",
        {
          staticStyle: { "min-width": "120px" },
          attrs: { "data-cy": "columnCondition" },
        },
        [
          _vm.item.showCondition
            ? _c("table-condition", {
                attrs: {
                  deviceId: _vm.item.id,
                  accessLevel: _vm.item.accessCode,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "td",
        {
          class: { "not-available-text": !_vm.item.showLastCommunication },
          staticStyle: { "min-width": "184px" },
          attrs: { "data-cy": "columnLastCommunication" },
        },
        [
          _vm.item.lastCommunication
            ? _c("span", [_vm._v(_vm._s(_vm.item.lastCommunication))])
            : _c("span", [
                _vm._v(_vm._s(_vm.$t("manageDevices.lastCommunicationNever"))),
              ]),
        ]
      ),
      _c(
        "td",
        { attrs: { "data-cy": "columnAqi" } },
        [
          _vm.item.showAQI
            ? _c("styled-reading", {
                attrs: { "data-cy": "aqiIcon", reading: _vm.item.reading },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "td",
        {
          staticClass: "text-xs-left right-aligned",
          attrs: { "data-cy": "columnActions" },
        },
        [
          _c("action-menu", {
            attrs: { deviceId: _vm.item.id, accessLevel: _vm.item.accessCode },
            on: { openDialog: _vm.openDialog },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }