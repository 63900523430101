var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-widget",
    {
      attrs: {
        deviceIds: _vm.deviceIds,
        widgetId: _vm.widgetId,
        widgetType: _vm.widgetType,
        widgetMaxDevices: _vm.widgetMaxDevices,
        title: _vm.name,
        subtitle: _vm.deviceType,
        fullscreen: _vm.fullscreen,
        "data-cy": "MapWidget",
      },
      on: {
        removeDevice: _vm.removeDevice,
        removeWidget: _vm.removeWidget,
        refreshDashboard: _vm.refreshDashboard,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "map-wrapper",
          attrs: { slot: "content" },
          slot: "content",
        },
        [
          _c(
            "div",
            { staticClass: "update-bar" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "6" } }),
                  _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "update-bar__item update-bar__item--last-contact",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("map.deviceInfo.lastContact")) +
                            ": " +
                            _vm._s(_vm.getTimeSinceDate(_vm.lastContact)) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "GmapMap",
            {
              ref: "gmap",
              staticClass: "google-map",
              attrs: {
                center: _vm.center,
                zoom: _vm.zoom,
                "map-type-id": "terrain",
                options: {
                  clickableIcons: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                  minZoom: 4,
                  maxZoom: 24,
                  restriction: {
                    latLngBounds: {
                      north: 85,
                      south: -85,
                      west: -180,
                      east: 180,
                    },
                  },
                  styles: [
                    {
                      featureType: "poi",
                      stylers: [
                        {
                          saturation: -100,
                        },
                        {
                          lightness: 30,
                        },
                      ],
                    },
                    {
                      featureType: "poi",
                      elementType: "labels.icon",
                      stylers: [
                        {
                          lightness: 55,
                        },
                      ],
                    },
                  ],
                },
              },
              on: {
                zoom_changed: _vm.updateZoom,
                center_changed: _vm.updateCenter,
              },
            },
            [
              _c("map-pin", {
                key: _vm.deviceId,
                ref: _vm.deviceId,
                attrs: { deviceId: _vm.deviceId, widget: true },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "map-legend" },
            [_c("map-legend", { attrs: { isWidget: "" } })],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }