var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("popover-last-telemetry-timestamp", {
        attrs: { deviceId: _vm.deviceId },
      }),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("aqi-main-value", { attrs: { aqi: _vm.currentReading.aqi } }),
          _c("v-spacer"),
          _c("aqi-averages", {
            ref: "averages",
            attrs: { deviceId: _vm.deviceId },
          }),
        ],
        1
      ),
      _c("aqi-percent-changed", {
        ref: "percentChanged",
        attrs: { deviceId: _vm.deviceId },
      }),
      _c("aqi-description", { attrs: { aqi: _vm.currentReading.aqi } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }