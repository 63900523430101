var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        fullscreen: _vm.$vuetify.breakpoint.xs,
        persistentOverlay: true,
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _vm.showNotifications
                ? _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass:
                          "popover-status-icon device-notifications-icon",
                        class: _vm.showNotificationsClass,
                        attrs: { text: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.open = true
                          },
                        },
                      },
                      on
                    ),
                    [
                      _c(
                        "v-badge",
                        {
                          attrs: {
                            color: "accent",
                            overlap: "",
                            right: "",
                            "offset-x": "12",
                            "offset-y": "14",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "badge",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.notificationsNum)),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("v-icon", { attrs: { color: "grey" } }, [
                            _vm._v("mdi-bell"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
        },
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "v-card-title",
                [
                  _c(
                    "div",
                    [
                      _c("v-icon", { staticClass: "header-icon" }, [
                        _vm._v("mdi-bell"),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("map.popover.menu.deviceNotifications")
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary--text mobile-menu",
                      attrs: { text: "" },
                      on: { click: _vm.viewAll },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("alertHistory.viewAll")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-subtitle",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: "",
                        "max-width": 600,
                        disabled: !_vm.isNameOverflowing,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  {
                                    ref: "deviceName",
                                    staticClass: "device-name",
                                  },
                                  on
                                ),
                                [_c("span", [_vm._v(_vm._s(_vm.deviceName))])]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.deviceName))])]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "actions",
          fn: function () {
            return [
              _c("v-spacer"),
              _c("div", { staticStyle: { "margin-right": "16px" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "clear-all-button black white--text",
                        attrs: {
                          loading: _vm.clearingAllLoading,
                          disabled: _vm.clearingInProgress,
                          text: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.clearAll(_vm.deviceId)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("buttons.clearAll")) + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary white--text",
                        attrs: { text: "" },
                        on: {
                          click: function ($event) {
                            _vm.open = false
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("buttons.done")) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "v-list",
        _vm._l(_vm.notifications, function (item, index) {
          return _c(
            "v-list-item",
            { key: index, attrs: { "three-line": "" } },
            [
              _c(
                "v-list-item-content",
                [
                  index > 0
                    ? _c("v-divider", {
                        staticStyle: { "margin-bottom": "20px" },
                      })
                    : _vm._e(),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(item.trigger.title)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass: "anchor-right",
                              staticStyle: { "text-transform": "capitalize" },
                            },
                            [_vm._v(" " + _vm._s(item.trigger.severity) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-list-item-subtitle", [
                            _vm._v(" " + _vm._s(item.device.name) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "anchor-right" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    new Date(
                                      item.triggeredAt
                                    ).toLocaleDateString()
                                  ) +
                                  " " +
                                  _vm._s(
                                    new Date(
                                      item.triggeredAt
                                    ).toLocaleTimeString()
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-list-item-action-text", [
                            _c("strong", [
                              _vm._v(" " + _vm._s(item.message) + " "),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "clear-button",
                              attrs: {
                                loading: _vm.buttonLoading["" + item.id],
                                disabled: _vm.clearingInProgress,
                                outlined: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clear(item.id)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("buttons.clear")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }