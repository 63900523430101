var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-row", { attrs: { "no-gutters": "" } }, [
    _c("div", { staticClass: "descriptive-text" }, [
      _vm.aqi != _vm.$t("map.popover.valueBlank")
        ? _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "map.popover.descriptions.aqi_" +
                      _vm.getAQIName(_vm.aqi) +
                      ".range"
                  )
                ) +
                ": "
            ),
          ])
        : _vm._e(),
      _vm._v(
        " " +
          _vm._s(
            _vm.$t(
              "map.popover.descriptions.aqi_" +
                _vm.getAQIName(_vm.aqi) +
                ".message"
            )
          ) +
          " "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }