var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        "data-cy": "accessAdditionalFeaturesDialog",
        fullscreen: _vm.$vuetify.breakpoint.xs,
        persistentOverlay: true,
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "v-card-title",
                { attrs: { "data-cy": "accessAdditionalFeaturesHeader" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("accessAdditionalFeaturesDialog.title")) +
                      " "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "black--text dialog-btn",
                  attrs: {
                    "v-if": _vm.$vuetify.breakpoint.xs,
                    text: "",
                    outlined: "",
                    "data-cy": "accessAdditionalFeaturesCancel",
                  },
                  on: { click: _vm.cancel },
                },
                [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary white--text",
                  attrs: {
                    disabled: !_vm.fieldsValid || _vm.isClosing,
                    loading: _vm.isClosing,
                    text: "",
                    "data-cy": "accessAdditionalFeaturesClose",
                  },
                  on: { click: _vm.close },
                },
                [
                  _c("div", { staticClass: "trailing-icon-text" }, [
                    _vm._v(_vm._s(_vm.$t("buttons.done"))),
                  ]),
                  _c("v-icon", { attrs: { right: "", light: "" } }, [
                    _vm._v("mdi-chevron-right"),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "v-card-text",
        [
          _vm._v(
            " " + _vm._s(_vm.$t("accessAdditionalFeaturesDialog.message")) + " "
          ),
          _c("v-checkbox", {
            attrs: {
              color: "primary",
              label: _vm.$t("accessAdditionalFeaturesDialog.addToBoth"),
              "data-cy": "addToBothCheckbox",
            },
            on: { click: _vm.change },
            model: {
              value: _vm.isAddToBoth,
              callback: function ($$v) {
                _vm.isAddToBoth = $$v
              },
              expression: "isAddToBoth",
            },
          }),
          _vm._l(_vm.subTierSelectByDeviceModel, function (model) {
            return _c(
              "div",
              { key: model.id, staticClass: "subscription-tier-select-group" },
              [
                _c("v-card-subtitle", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("accessAdditionalFeaturesDialog.selectTitle", {
                          modelName: _vm.deviceModelNames[model],
                          subscription: _vm.$t("activateDevice.subscription"),
                        })
                      ) +
                      " "
                  ),
                ]),
                _c("v-select", {
                  staticClass: "text-field-required",
                  attrs: {
                    items: _vm.subscriptionTierItems,
                    "item-text": "text",
                    "item-value": "value",
                    label: _vm.$t("activateDevice.subscriptionTier"),
                    hint: _vm.selectHintByModel[model],
                    "persistent-hint": "",
                    rules: [_vm.rules.required],
                    "data-cy": "apiSubscriptionTierSelect",
                  },
                  on: { input: _vm.subTierChange },
                  model: {
                    value: _vm.subTiers[model],
                    callback: function ($$v) {
                      _vm.$set(_vm.subTiers, model, $$v)
                    },
                    expression: "subTiers[model]",
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }