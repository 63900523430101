var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "popover-title",
        {
          attrs: {
            name: _vm.name,
            deviceType: _vm.deviceType,
            serial: _vm.serial,
            accessLevel: _vm.accessLevel,
            tabIndex: "0",
          },
        },
        [
          _c("popover-menu", {
            attrs: {
              slot: "menu",
              deviceId: _vm.deviceId,
              accessLevel: _vm.accessLevel,
              permissions: _vm.permissions,
              "data-cy": "popoverMenu",
            },
            on: { unregister: _vm.closeInfoWindow },
            slot: "menu",
          }),
          _c("popover-toolbar", {
            attrs: { slot: "status", deviceId: _vm.deviceId },
            slot: "status",
          }),
        ],
        1
      ),
      _c("hr"),
      _c(
        "div",
        { staticClass: "card-content", attrs: { "data-cy": "devicePopover" } },
        [
          _c("popover-main-content", {
            attrs: { deviceId: _vm.deviceId, permissions: _vm.permissions },
          }),
          _c(
            "v-btn",
            {
              staticClass: "black--text history-btn",
              attrs: { outlined: "", text: "", "data-cy": "openHistoryDialog" },
              on: { click: _vm.openHistoryDialog },
            },
            [_vm._v(" " + _vm._s(_vm.$t("map.historyDialog.title")) + " ")]
          ),
        ],
        1
      ),
      _c("history-dialog", {
        attrs: {
          open: _vm.historyOpen,
          currentDeviceId: _vm.historyId,
          isDetachedDialog: true,
          removableDevices: true,
        },
        on: {
          close: function ($event) {
            return _vm.closeHistoryDialog()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }