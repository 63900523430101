var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tbody",
    _vm._l(_vm.items, function (item) {
      return _c("tr", { key: item.id }, [
        _c("td", { attrs: { "data-cy": "columnEmail" } }, [
          _vm._v(" " + _vm._s(item.email) + " "),
        ]),
        _c("td", { attrs: { "data-cy": "columnName" } }, [
          _vm._v(" " + _vm._s(item.username) + " "),
        ]),
        _c("td", { attrs: { "data-cy": "columnAccountId" } }, [
          _vm._v(" " + _vm._s(item.accountId) + " "),
        ]),
        _c("td", { attrs: { "data-cy": "columnSubscription" } }, [
          _vm._v(" " + _vm._s(item.subscription) + " "),
        ]),
        _c("td", { attrs: { "data-cy": "columnSapAcctId" } }, [
          _vm._v(" " + _vm._s(item.sapAcctId) + " "),
        ]),
        _c("td", { attrs: { "data-cy": "columnLastLogin" } }, [
          item.lastLogIn != 0
            ? _c("div", { staticClass: "valid-date" }, [
                _vm._v(
                  " " + _vm._s(new Date(item.lastLogIn).toLocaleString()) + " "
                ),
              ])
            : _vm._e(),
        ]),
        _c(
          "td",
          { staticClass: "text-xs-left right-aligned" },
          [
            _c("account-action-menu", {
              attrs: {
                accountId: item.accountId,
                userId: item.userId,
                sapId: item.sapAcctId,
                email: item.email,
              },
            }),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }