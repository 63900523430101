var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-subtitle",
    {
      staticClass: "small-subtitle",
      attrs: { "data-cy": "lastDeviceMeasurementText" },
    },
    [
      _c("span", { class: { inactiveTimestamp: !_vm.activeLastHour } }, [
        _c("span", { staticClass: "lastMeasurementShort" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("map.popover.timestamp.lastMeasurementShort")) +
              ": "
          ),
        ]),
        _c("span", { staticClass: "lastMeasurement" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("map.popover.timestamp.lastMeasurement")) + ": "
          ),
        ]),
        _c("span", { staticClass: "value" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.timestamp !=
                  _vm.$t("map.popover.timestamp.lastMeasurementNever")
                  ? new Date(_vm.timestamp).toLocaleString()
                  : _vm.timestamp
              ) +
              " "
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }