var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hideLegend
    ? _c(
        "div",
        [
          _c(
            "v-card",
            { staticClass: "no-bottom-border-radius", attrs: { width: "250" } },
            [
              _c(
                "v-card-subtitle",
                {
                  staticClass: "title",
                  attrs: { height: "40", "data-cy": "mapLegendToggle" },
                  on: { click: _vm.toggle },
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("map.legend.title")) + " "),
                  _c("v-spacer"),
                  _c(
                    "span",
                    { staticClass: "right" },
                    [
                      _c(
                        "v-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.open,
                              expression: "!open",
                            },
                          ],
                        },
                        [_vm._v("mdi-chevron-up")]
                      ),
                      _c(
                        "v-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.open,
                              expression: "open",
                            },
                          ],
                        },
                        [_vm._v("mdi-chevron-down")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-expand-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.open,
                      expression: "open",
                    },
                  ],
                  staticClass:
                    "bottom-card no-top-border-radius no-bottom-border-radius",
                  attrs: { flat: "", "data-cy": "mapLegendExpanded" },
                },
                [
                  _c("div", { staticClass: "subtitle-large hide-large" }, [
                    _vm._v(" " + _vm._s(_vm.$t("map.legend.levels")) + " "),
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "legend", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "hide-small", attrs: { cols: "18" } },
                        [
                          _c("div", { staticClass: "subtitle" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("map.legend.levels")) + " "
                            ),
                          ]),
                        ]
                      ),
                      _c("v-col", { attrs: { cols: "18" } }, [
                        _c("div", { staticClass: "entry good" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "map.popover.descriptions.aqi_good.title"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "range" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "map.popover.descriptions.aqi_good.range"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("v-col", { attrs: { cols: "18" } }, [
                        _c("div", { staticClass: "entry moderate" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "map.popover.descriptions.aqi_moderate.title"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "range" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "map.popover.descriptions.aqi_moderate.range"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("v-col", { attrs: { cols: "18" } }, [
                        _c(
                          "div",
                          { staticClass: "entry unhealthy-sensitive" },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "map.popover.descriptions.aqi_unhealthy_sensitive.title"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "range" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "map.popover.descriptions.aqi_unhealthy_sensitive.range"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "18" } }, [
                        _c("div", { staticClass: "entry unhealthy" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "map.popover.descriptions.aqi_unhealthy.title"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "range" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "map.popover.descriptions.aqi_unhealthy.range"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("v-col", { attrs: { cols: "18" } }, [
                        _c("div", { staticClass: "entry very-unhealthy" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "map.popover.descriptions.aqi_very_unhealthy.title"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "range" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "map.popover.descriptions.aqi_very_unhealthy.range"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("v-col", { attrs: { cols: "18" } }, [
                        _c("div", { staticClass: "entry hazardous" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "map.popover.descriptions.aqi_hazardous.title"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "range" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "map.popover.descriptions.aqi_hazardous.range"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }