var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        "data-cy": "devicesUpdatedDialog",
        fullscreen: _vm.$vuetify.breakpoint.xs,
        persistentOverlay: true,
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "v-card-title",
                { attrs: { "data-cy": "devicesUpdatedHeader" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("devicesUpdatedDialog.newPmSensor.title")) +
                      " "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "actions",
          fn: function () {
            return [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary white--text",
                  attrs: { text: "", "data-cy": "devicesUpdatedClose" },
                  on: { click: _vm.close },
                },
                [_vm._v(_vm._s(_vm.$t("buttons.done")) + " ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("fusion-scroll", [
        _vm.devicesHavingTsiPmSensor.length > 0
          ? _c(
              "div",
              [
                _c("v-card-text", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "devicesUpdatedDialog.newPmSensor.tsiSensorMessage"
                        )
                      ) +
                      " "
                  ),
                  _c(
                    "ul",
                    {
                      attrs: {
                        id: "devicesHavingTsiPmSensor",
                        "data-cy": "devicesHavingTsiPmSensor",
                      },
                    },
                    _vm._l(_vm.devicesHavingTsiPmSensor, function (device) {
                      return _c("li", { key: device.serial }, [
                        _vm._v(
                          " " +
                            _vm._s(device.modelName) +
                            ' "' +
                            _vm._s(device.name) +
                            '" (' +
                            _vm._s(device.serial) +
                            ") "
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.devicesHavingNonTsiPmSensor.length > 0
          ? _c(
              "div",
              [
                _c("v-card-text", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "devicesUpdatedDialog.newPmSensor.nonTsiSensorMessage"
                        )
                      ) +
                      " "
                  ),
                  _c(
                    "ul",
                    {
                      attrs: {
                        id: "devicesHavingNonTsiPmSensor",
                        "data-cy": "devicesHavingNonTsiPmSensor",
                      },
                    },
                    _vm._l(_vm.devicesHavingNonTsiPmSensor, function (device) {
                      return _c("li", { key: device.serial }, [
                        _vm._v(
                          " " +
                            _vm._s(device.modelName) +
                            ' "' +
                            _vm._s(device.name) +
                            '" (' +
                            _vm._s(device.serial) +
                            ") "
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }