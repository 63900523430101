var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-list",
        [
          _c("measurement-options", {
            attrs: {
              deviceIds: _vm.deviceIds,
              passedinParameter: _vm.passedinParameter,
              widgetId: _vm.widgetId,
            },
            on: { parameterChange: _vm.parameterChange },
          }),
          _c("v-divider"),
          _c(
            "v-list-item",
            {
              attrs: {
                "data-cy": "exportData",
                disabled: _vm.disableExportButton,
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.openExportData.apply(null, arguments)
                },
              },
            },
            [
              _c("v-icon", [_vm._v("mdi-file-download")]),
              _c("v-list-item-title", [
                _vm._v(_vm._s(_vm.$t("map.historyDialog.dropdown.exportCsv"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("export-data-dialog", {
        attrs: {
          open: _vm.exportData,
          devices: _vm.selectedDevices,
          permissions: _vm.permissions,
        },
        on: { close: _vm.closeExportData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }