// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 style=%27width:24px;height:24px%27 viewBox=%270 0 24 24%27%3E%3Cpath fill=%27currentColor%27 d=%27M22,22H20V20H22V22M22,18H20V16H22V18M18,22H16V20H18V22M18,18H16V16H18V18M14,22H12V20H14V22M22,14H20V12H22V14Z%27 /%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vue-grid-layout {\n  background: transparent;\n}\n.vue-grid-item {\n  touch-action: pan-x pan-y !important;\n  width: 400px;\n}\n.vue-grid-item.vue-grid-placeholder {\n  background: #00aeef !important;\n  border-radius: 4px;\n}\n.vue-grid-item.vue-grid-placeholder .vue-resizable-handle {\n  display: none;\n}\n.vue-grid-item:not(.vue-grid-placeholder) {\n  background: transparent;\n}\n.vue-grid-item.widget--fullscreen {\n  position: fixed !important;\n  z-index: 5;\n  width: 100% !important;\n  height: 100% !important;\n  top: 0 !important;\n  left: 0 !important;\n  overflow-y: auto;\n  transform: none !important;\n}\n.vue-grid-item .debug {\n  position: absolute;\n  top: 100%;\n  left: 0;\n  font-size: 75%;\n  line-height: 1;\n  margin: 0.25em 0;\n}\n.vue-grid-item .widget {\n  width: 100%;\n  height: 100%;\n}\n.vue-grid-item .resizing {\n  opacity: 0.9;\n}\n.vue-grid-item .vue-resizable-handle {\n  touch-action: none;\n  border-radius: 4px;\n  background-color: #fff;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.vue-draggable-handle {\n  touch-action: none;\n  cursor: grab;\n}\n.vue-draggable-dragging .vue-draggable-handle {\n  cursor: grabbing;\n}\n.widget-fullscreen .vue-draggable-handle {\n  cursor: default;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
