var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-icon", {
    attrs: {
      showIcon: _vm.showIcon,
      imgSrc: _vm.imgSrc,
      imgAlt: "Communication Icon",
      iconText: _vm.iconText,
      tooltipContentClass: "communication-status-tooltip",
      dataCy: "communicationStatus",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }