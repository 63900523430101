var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "percent-change",
      class: { hidden: _vm.percentChange === null },
      attrs: { "no-gutters": "", "data-cy": "percentChange" },
    },
    [
      _vm.percentChange > 0
        ? _c("v-icon", { staticClass: "arrow-icon text--primary" }, [
            _vm._v(" mdi-arrow-up-bold "),
          ])
        : _vm._e(),
      _vm.percentChange < 0
        ? _c("v-icon", { staticClass: "arrow-icon text--primary" }, [
            _vm._v(" mdi-arrow-down-bold "),
          ])
        : _vm._e(),
      _vm.percentChange !== 0
        ? _c("span", { staticClass: "change" }, [
            _vm._v(" " + _vm._s(Math.abs(_vm.percentChange)) + "% "),
          ])
        : _vm._e(),
      _vm.percentChange === 0
        ? _c("span", [
            _vm._v(
              " " + _vm._s(_vm.$t("map.popover.percentChange.noChange")) + " "
            ),
          ])
        : _vm._e(),
      _c("span", { staticClass: "message" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("map.popover.percentChange.last24Hours")) + " "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }