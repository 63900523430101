var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "GmapMap",
        {
          ref: "gmap",
          staticStyle: { height: "100%", width: "100%" },
          attrs: {
            center: _vm.center,
            zoom: _vm.zoom,
            "map-type-id": "terrain",
            options: {
              clickableIcons: false,
              streetViewControl: false,
              mapTypeControl: true,
              fullscreenControl: false,
              minZoom: 4,
              maxZoom: 24,
              restriction: {
                latLngBounds: {
                  north: 85,
                  south: -85,
                  west: -180,
                  east: 180,
                },
              },
              styles: [
                {
                  featureType: "poi",
                  stylers: [
                    {
                      saturation: -100,
                    },
                    {
                      lightness: 30,
                    },
                  ],
                },
                {
                  featureType: "poi",
                  elementType: "labels.icon",
                  stylers: [
                    {
                      lightness: 55,
                    },
                  ],
                },
              ],
            },
          },
          on: {
            center_changed: function ($event) {
              return _vm.saveMapLocation($event)
            },
            zoom_changed: function ($event) {
              return _vm.updateZoom($event)
            },
            click: _vm.closeInfoWindow,
          },
        },
        [
          _vm._l(_vm.devices, function (coords, deviceId, index) {
            return _c("map-pin", {
              key: deviceId,
              ref: _vm.setPinRef(deviceId),
              refInFor: true,
              attrs: {
                deviceId: deviceId,
                clickable: _vm.clickable,
                moving: _vm.setPinIsMoving(deviceId),
                zIndex: _vm.setPinZIndex(deviceId, index),
                opacity: _vm.setPinOpacity(deviceId),
                "data-cy": "mapPin",
              },
              on: {
                clicked: function ($event) {
                  return _vm.toggleInfoWindow($event)
                },
                closed: function ($event) {
                  return _vm.closePin($event)
                },
              },
            })
          }),
          _c(
            "GmapInfoWindow",
            {
              attrs: {
                options: _vm.infoOptions,
                position: _vm.infoWindowPos,
                opened: _vm.infoWinOpen,
              },
              on: { closeclick: _vm.closeInfoWindow },
            },
            [
              _c("device-popover", {
                attrs: { deviceId: _vm.currentPinId },
                on: { close: _vm.closeInfoWindow },
              }),
            ],
            1
          ),
          _c("map-settings-dialog", {
            attrs: { open: _vm.dialogs.mapSettings },
            on: {
              close: function ($event) {
                _vm.dialogs.mapSettings = false
              },
            },
          }),
        ],
        2
      ),
      _c(
        "v-btn",
        {
          staticClass: "map-settings-button",
          attrs: {
            color: "white",
            small: "",
            "data-cy": "mapSettingsButton",
            "min-width": "40",
            height: "40",
            width: "40",
          },
          on: {
            click: function ($event) {
              return _vm.toggleMapSettings()
            },
          },
        },
        [_c("v-icon", [_vm._v("mdi-settings")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }