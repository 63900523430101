var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unregister-actions-row" },
    [
      _c(
        "v-btn",
        {
          staticClass: "black--text",
          attrs: {
            outlined: "",
            text: "",
            "data-cy": "unregisterCancelButton",
            disabled: _vm.isUnregisteringDevice,
          },
          on: { click: _vm.close },
        },
        [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
      ),
      _c("v-spacer"),
      _c(
        "v-btn",
        {
          staticClass: "warning white--text",
          attrs: {
            text: "",
            "data-cy": "unregisterConfirmButton",
            loading: _vm.isUnregisteringDevice,
            disabled: _vm.isUnregisteringDevice,
          },
          on: { click: _vm.unregisterConfirm },
        },
        [_vm._v(_vm._s(_vm.$t("deviceUnregister.unregisterCard.actionButton")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }