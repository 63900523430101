var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      key: _vm.item.id,
      attrs: { "data-cy": "device" + _vm.item.deviceSerial + "Row" },
    },
    [
      _c("td", { staticClass: "mobile-list", class: { "ml-6": _vm.isChild } }, [
        _c(
          "div",
          { staticClass: "item-title" },
          [
            _c("action-menu", {
              attrs: {
                deviceId: _vm.item.id,
                accessLevel: _vm.item.accessCode,
              },
              on: { openDialog: _vm.openDialog },
            }),
            _c(
              "v-tooltip",
              {
                attrs: {
                  bottom: "",
                  "max-width": 600,
                  disabled: !_vm.isNameOverflowing[_vm.item.id],
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g(
                            { ref: _vm.item.id, staticClass: "device-name" },
                            on
                          ),
                          [
                            _vm.item.deviceName
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.item.deviceName)),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("manageDevices.unnamedDevice")
                                    )
                                  ),
                                ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              },
              [_c("span", [_vm._v(_vm._s(_vm.item.deviceName))])]
            ),
            _c("v-spacer"),
            _vm.canAccess(_vm.access.FULL, _vm.item.accessCode) &&
            _vm.item.showCondition
              ? _c("table-condition", {
                  attrs: {
                    deviceId: _vm.item.id,
                    accessLevel: _vm.item.accessCode,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "ul",
          {
            staticClass: "flex-content",
            class: { expandable: _vm.itemCanBeExpanded },
          },
          [
            _c("li", { staticClass: "flex-item" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("manageDevices.tableHeaders.deviceLocationName")
                    ) +
                    ":  "
                ),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.item.deviceLocationName))]),
            ]),
            _c("li", { staticClass: "flex-item" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("manageDevices.tableHeaders.deviceType")) +
                    ":  "
                ),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.item.deviceType))]),
            ]),
            _c("li", { staticClass: "flex-item" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("manageDevices.tableHeaders.deviceModel")) +
                    ":  "
                ),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.item.deviceModel))]),
            ]),
            _c("li", { staticClass: "flex-item" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("manageDevices.tableHeaders.deviceSerial")) +
                    ":  "
                ),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.item.deviceSerial))]),
            ]),
            _c("li", { staticClass: "flex-item" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("manageDevices.tableHeaders.deviceSubscription")
                    ) +
                    ":  "
                ),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.item.deviceSubscription))]),
            ]),
            _c("li", { staticClass: "flex-item" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("manageDevices.tableHeaders.access")) + ": "
                ),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.item.access))]),
            ]),
            _c("li", { staticClass: "flex-item" }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("manageDevices.tableHeaders.lastCommunication")
                    ) +
                    ":  "
                ),
              ]),
              _vm.item.lastCommunication
                ? _c("span", [_vm._v(_vm._s(_vm.item.lastCommunication))])
                : _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("manageDevices.lastCommunicationNever"))
                    ),
                  ]),
            ]),
            _c("li", { staticClass: "flex-item" }, [
              _c("span", [_vm._v(" " + _vm._s(_vm.measurementLabel) + ":  ")]),
              _c(
                "span",
                [
                  _vm.item.showAQI
                    ? _c("styled-reading", {
                        attrs: { reading: _vm.item.reading },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm.itemCanBeExpanded
          ? _c(
              "div",
              { staticClass: "text-end my-2" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary--text flex-row-reverse",
                    attrs: { text: "" },
                    on: {
                      click: function ($event) {
                        return _vm.expand(!_vm.isExpanded)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isExpanded
                            ? _vm.$t(
                                "manageDevices.mobileExpansionOptions.hide"
                              )
                            : _vm.$t(
                                "manageDevices.mobileExpansionOptions.view"
                              )
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }