var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": _vm.dialogContentClass,
        "hide-overlay": _vm.isDetachedDialog,
        persistent: true,
        "no-click-animation": _vm.isDetachedDialog,
        "data-cy": "historyDialog",
        fullscreen: _vm.$vuetify.breakpoint.xs || _vm.fullscreen,
        "max-width": _vm.isDetachedDialog ? 320 : 600,
        "max-height": "800",
      },
      on: { input: _vm.close },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              return [_vm._t("activator", null, null, on)]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("history-menu", {
                attrs: {
                  selectedDevices: _vm.selectedDevices,
                  permissions: _vm.permissions,
                  passedinParameter: _vm.selectedParameter,
                },
                on: {
                  "update:passedinParameter": function ($event) {
                    _vm.selectedParameter = $event
                  },
                  "update:passedin-parameter": function ($event) {
                    _vm.selectedParameter = $event
                  },
                  parameterChange: _vm.parameterChange,
                },
              }),
              _c("div", { staticClass: "history-title" }, [
                _vm._v(_vm._s(_vm.$t("map.historyDialog.title"))),
              ]),
              _c("v-spacer"),
              _c("div", { staticClass: "popoverIcons" }, [
                !_vm.$vuetify.breakpoint.xs
                  ? _c(
                      "span",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              text: "",
                              "data-cy": "toggleFullscreenHistoryDialog",
                            },
                            on: { click: _vm.toggleFullscreen },
                          },
                          [
                            _vm.fullscreen
                              ? _c("v-icon", { attrs: { color: "grey" } }, [
                                  _vm._v(" mdi-fullscreen-exit "),
                                ])
                              : _c("v-icon", { attrs: { color: "grey" } }, [
                                  _vm._v("mdi-fullscreen"),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "span",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", "data-cy": "closeHistoryDialog" },
                        on: { click: _vm.close },
                      },
                      [
                        _c("v-icon", { attrs: { color: "grey" } }, [
                          _vm._v("mdi-close"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("history-graph", {
            attrs: {
              open: _vm.isOpen,
              isFullscreen: _vm.isFullscreen,
              isDetachedDialog: _vm.isDetachedDialog,
              removableDevices: _vm.removableDevices,
              selectedDevices: _vm.selectedDevices,
              selectedParameter: _vm.selectedParameter,
              permissions: _vm.permissions,
              accountId: _vm.accountId,
            },
            on: { close: _vm.close, removeDevice: _vm.removeDevice },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }