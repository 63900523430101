var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "no-top-padding", attrs: { flat: "" } },
    [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "loading-container" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-text",
        {
          staticClass: "no-top-padding buffered-content",
          class: { "opacity-20": _vm.isLoading },
        },
        [
          _c("v-card-title", [
            _vm._v(_vm._s(_vm.$t("accountSubscriptions.subscriptionDetails"))),
          ]),
          _vm.hasTSILinkSubs
            ? _c(
                "div",
                { attrs: { "data-cy": "userProfileSubscriptionsWebsite" } },
                [
                  _c("v-card-subtitle", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$t("accountSubscriptions.TSISubscriptions"))
                      ),
                    ]),
                  ]),
                  _vm.hasSubscriptions.basic
                    ? _vm._l(_vm.basicSubscriptions, function (sub) {
                        return _c(
                          "v-card-text",
                          { key: sub.id, staticClass: "less-top" },
                          [
                            _vm._v(" " + _vm._s(sub.name) + " "),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("accountSubscriptions.deviceAllowance")
                                ) +
                                " " +
                                _vm._s(
                                  _vm.$t(
                                    "accountSubscriptions.deviceSubscriptionsUsedRemaining",
                                    {
                                      qty: sub.totalUsed,
                                      remaining: sub.totalQtyRemaining,
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      })
                    : _vm._e(),
                  _vm.hasSubscriptions.premium
                    ? _vm._l(_vm.premiumSubscriptions, function (sub) {
                        return _c(
                          "v-card-text",
                          { key: sub.id, staticClass: "less-top" },
                          [
                            _vm._v(" " + _vm._s(sub.name) + " "),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("accountSubscriptions.deviceAllowance")
                                ) +
                                " " +
                                _vm._s(
                                  _vm.$t(
                                    "accountSubscriptions.deviceSubscriptionsUsedRemaining",
                                    {
                                      qty: sub.totalUsed,
                                      remaining: sub.totalQtyRemaining,
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      })
                    : _vm._e(),
                  _vm.hasOnlyFreeTier
                    ? _vm._l(_vm.freeTier, function (sub) {
                        return _c(
                          "v-card-text",
                          { key: sub.id, attrs: { "data-cy": sub.id } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("accountSubscriptions.freeTier")
                                ) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("accountSubscriptions.deviceAllowance")
                                ) +
                                " " +
                                _vm._s(
                                  _vm.$t(
                                    "accountSubscriptions.deviceSubscriptionsUsedRemaining",
                                    {
                                      qty: sub.totalUsed,
                                      remaining: sub.totalQtyRemaining,
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      })
                    : _vm._e(),
                  _vm.hasSubscriptions.genericSubsWithoutSmartBridgeDeviceFee
                    ? _vm._l(
                        _vm.genericSubsWithoutSmartBridgeDeviceFee,
                        function (sub) {
                          return _c(
                            "v-card-text",
                            {
                              key: sub.id,
                              staticClass: "less-top",
                              attrs: { "data-cy": sub.id },
                            },
                            [_vm._v(" " + _vm._s(sub.name) + " ")]
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.hasSubscriptions.smartBridgeDeviceFeeSub
                    ? _vm._l(_vm.smartBridgeDeviceFeeSub, function (sub) {
                        return _c(
                          "v-card-text",
                          {
                            key: sub.id,
                            staticClass: "less-top",
                            attrs: { "data-cy": sub.id },
                          },
                          [
                            _vm._v(" " + _vm._s(sub.name) + " "),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("accountSubscriptions.deviceAllowance")
                                ) +
                                " " +
                                _vm._s(
                                  _vm.$t(
                                    "accountSubscriptions.deviceSubscriptionsUsedRemaining",
                                    {
                                      qty: sub.totalUsed,
                                      remaining: sub.totalQtyRemaining,
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      })
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.hasSubscriptions.dataServices
            ? _c(
                "div",
                [
                  _c("v-card-subtitle", { staticClass: "less-top" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "accountSubscriptions.dataServicesSubscriptions"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._l(_vm.dataSubscriptions, function (sub) {
                    return _c(
                      "v-card-text",
                      { key: sub.id, staticClass: "less-top" },
                      [
                        _vm._v(" " + _vm._s(sub.name) + " "),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("accountSubscriptions.deviceAllowance")
                            ) +
                            " " +
                            _vm._s(
                              _vm.$t(
                                "accountSubscriptions.deviceSubscriptionsUsedRemaining",
                                {
                                  qty: sub.totalUsed,
                                  remaining: sub.totalQtyRemaining,
                                }
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _c("br"),
          _c("div", [
            _vm._v(_vm._s(_vm.$t("accountSubscriptions.upgradePrompt"))),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "black--text magenta-btn-color",
              attrs: { outlined: "", id: "upgradeButton", text: "" },
              on: {
                click: function ($event) {
                  return _vm.upgrade()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("buttons.upgrade")))]
          ),
          _c("br"),
          _c("div", [
            _vm._v(_vm._s(_vm.$t("accountSubscriptions.activationPrompt"))),
          ]),
          !_vm.validatingCode
            ? _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.validActivationCode,
                    callback: function ($$v) {
                      _vm.validActivationCode = $$v
                    },
                    expression: "validActivationCode",
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.rules,
                      "error-messages": _vm.errorMessages,
                      "success-messages": _vm.successMessages,
                      "data-cy": "activationCodeInput",
                    },
                    on: { focus: _vm.reset },
                    model: {
                      value: _vm.activationCode,
                      callback: function ($$v) {
                        _vm.activationCode = $$v
                      },
                      expression: "activationCode",
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "activate-button",
                      attrs: {
                        id: "activation-button",
                        color: "primary",
                        text: "",
                        disabled: _vm.disableActivate,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.activate()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.activate")))]
                  ),
                ],
                1
              )
            : _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" },
              }),
        ],
        1
      ),
      _c(
        "v-card-actions",
        { attrs: { text: "" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "red-border red--text",
              attrs: {
                to: "/manage-subscriptions",
                outlined: "",
                text: "",
                "data-cy": "manageSubscriptionsButton",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("accountSubscriptions.manageSubscriptions")))]
          ),
          _c("v-spacer"),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "primary white--text",
              attrs: { text: "", "data-cy": "userProfileSubscriptionsDone" },
              on: { click: _vm.close },
            },
            [_vm._v(_vm._s(_vm.$t("buttons.done")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }