var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "service-container" }, [
    _c("div", { staticClass: "item logo-container" }, [
      _c(
        "a",
        {
          attrs: {
            href: _vm.sanitizeUrl,
            target: "_blank",
            title: this.serviceName + " Status Page",
            "data-cy": "serviceLogo",
          },
        },
        [
          _c("img", {
            staticClass: "logo",
            attrs: {
              src: "/img/" + this.serviceLower + "-logo.png",
              alt: "logo",
            },
          }),
        ]
      ),
    ]),
    _vm.loading
      ? _c(
          "div",
          { staticClass: "icon", attrs: { "data-cy": "loadingIcon" } },
          [
            _c("v-progress-circular", {
              attrs: { indeterminate: "", color: "primary" },
            }),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "item icon-container",
            attrs: { "data-cy": "statusIcons" },
          },
          [
            _c(
              "v-icon",
              {
                staticClass: "icon",
                attrs: { color: _vm.iconColor, large: "" },
              },
              [_vm._v(" " + _vm._s(_vm.icon) + " ")]
            ),
          ],
          1
        ),
    _c("div", { staticClass: "item text-container" }, [
      _c("span", { staticClass: "text", attrs: { "data-cy": "text" } }, [
        _vm._v(" " + _vm._s(_vm.statusText) + " "),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.checkedText) + " "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }