var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "expand-collapse-buttons": true,
        "is-mobile": this.$vuetify.breakpoint.smAndDown,
      },
    },
    [
      _c(
        "v-btn",
        {
          attrs: { "data-cy": "expandAll" },
          on: {
            click: function ($event) {
              return _vm.$emit("expandAll")
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("manageSubscriptions.tableFooter.expandAll")))]
      ),
      _c("span", { staticClass: "separator" }, [_vm._v(_vm._s("|"))]),
      _c(
        "v-btn",
        {
          attrs: { "data-cy": "collapseAll" },
          on: {
            click: function ($event) {
              return _vm.$emit("collapseAll")
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("manageSubscriptions.tableFooter.collapseAll")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }