var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "multiple-devices-success-message" }, [
    _c(
      "div",
      { staticClass: "success-icon" },
      [
        _c("v-icon", { attrs: { color: "green" } }, [
          _vm._v(" " + _vm._s(_vm.successIcon) + " "),
        ]),
      ],
      1
    ),
    _vm.formattedMessage && _vm.formattedMessage.length > 1
      ? _c("div", { staticClass: "success-description" }, [
          _vm._v(" " + _vm._s(_vm.formattedMessage[0]) + " "),
          _c("span", { staticClass: "file-name" }, [
            _vm._v(" " + _vm._s(_vm.fileName) + " "),
          ]),
          _vm._v(" " + _vm._s(_vm.formattedMessage[1]) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }