var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        title: _vm.$t("map.deviceInfo.title"),
        "data-cy": "deviceInfoDialog",
        fullscreen: _vm.$vuetify.breakpoint.xs,
        persistentOverlay: true,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              return [_vm._t("activator", null, null, on)]
            },
          },
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  "v-card-title",
                  [
                    _c("v-icon", { staticClass: "header-icon" }, [
                      _vm._v("mdi-information"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.$t("map.deviceInfo.title")) + " "),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    staticClass: "primary white--text",
                    attrs: { text: "", "data-cy": "deviceInfoDoneButton" },
                    on: { click: _vm.close },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.done")) + " ")]
                ),
              ]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    { staticClass: "device-name" },
                    [
                      _vm.enableDeviceLocationName
                        ? _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("map.deviceInfo.deviceName")
                                    ) +
                                    ": "
                                ),
                              ]),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    "max-width": 600,
                                    disabled: !_vm.isNameOverflowing,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-list-item-subtitle",
                                              _vm._g({ ref: "deviceName" }, on),
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      "data-cy":
                                                        "deviceInfoName",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.friendlyName)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3084492270
                                  ),
                                },
                                [_c("span", [_vm._v(_vm._s(_vm.friendlyName))])]
                              ),
                              _c(
                                "v-list-item-title",
                                { staticStyle: { "margin-top": "5px" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("map.deviceInfo.locationName")
                                      ) +
                                      ": "
                                  ),
                                ]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    "max-width": 600,
                                    disabled: !_vm.isLocationNameOverflowing,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-list-item-subtitle",
                                              _vm._g(
                                                { ref: "deviceLocationName" },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      "data-cy":
                                                        "deviceInfoLocation",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.locationName)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2201251123
                                  ),
                                },
                                [_c("span", [_vm._v(_vm._s(_vm.locationName))])]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("map.deviceInfo.name")) +
                                    ": "
                                ),
                              ]),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    "max-width": 600,
                                    disabled: !_vm.isNameOverflowing,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-list-item-subtitle",
                                            _vm._g({ ref: "deviceName" }, on),
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    "data-cy": "deviceInfoName",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.friendlyName)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [_c("span", [_vm._v(_vm._s(_vm.friendlyName))])]
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    { attrs: { "data-cy": "deviceInfoDevice" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("map.deviceInfo.device")) +
                                ": "
                            ),
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(" " + _vm._s(_vm.modelDisplayName) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { "data-cy": "deviceInfoModel" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("map.deviceInfo.model")) +
                                ": "
                            ),
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(" " + _vm._s(_vm.displayModel) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { "data-cy": "deviceInfoSubModel" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("map.deviceInfo.subModel")) +
                                ": "
                            ),
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(" " + _vm._s(_vm.submodel) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { "data-cy": "deviceInfoSerial" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("map.deviceInfo.serial")) +
                                ": "
                            ),
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(" " + _vm._s(_vm.serial) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    { attrs: { "data-cy": "deviceInfoLastCommunication" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("map.deviceInfo.lastCommunication")
                                ) +
                                ": "
                            ),
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getTimeSinceDate(_vm.lastCommunication)
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isEthernet
                    ? _c(
                        "v-list-item",
                        { attrs: { "data-cy": "deviceInfoWiredConnection" } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "map.deviceInfo.connectionSignalStrength"
                                      )
                                    ) +
                                    ": "
                                ),
                              ]),
                              _c("v-list-item-subtitle", [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ethernetIcon,
                                    alt: "Ethernet Icon",
                                    width: "18",
                                    height: "18",
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("map.deviceInfo.wiredConnection")
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-list-item",
                        { attrs: { "data-cy": "deviceInfoWifi" } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("map.deviceInfo.wifiStrength")
                                    ) +
                                    ": "
                                ),
                              ]),
                              _vm.showConnectivity
                                ? _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.connectionStrengthText) +
                                        " "
                                    ),
                                  ])
                                : _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("map.deviceInfo.notApplicable")
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c(
                    "v-list-item",
                    { attrs: { "data-cy": "deviceInfoRssi" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("map.deviceInfo.rssi")) + ": "
                            ),
                          ]),
                          _vm.isEthernet || !_vm.showConnectivity
                            ? _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("map.deviceInfo.notApplicable")
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.rssi) +
                                    " " +
                                    _vm._s(_vm.$t("map.deviceInfo.rssiUnits")) +
                                    " "
                                ),
                              ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { "data-cy": "deviceInfoFirmware" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("map.deviceInfo.firmware")) +
                                ": "
                            ),
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(" " + _vm._s(_vm.firmware) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("device-subscription-row", {
        attrs: {
          deviceId: _vm.deviceId,
          showSubscriptionType: _vm.subscriptionTypes.WEBSITE,
        },
      }),
      _c("device-subscription-row", {
        attrs: {
          deviceId: _vm.deviceId,
          showSubscriptionType: _vm.subscriptionTypes.DATA_SERVICES,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }