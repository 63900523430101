import { render, staticRenderFns } from "./release-notes.vue?vue&type=template&id=6636fc51&"
import script from "./release-notes.vue?vue&type=script&lang=js&"
export * from "./release-notes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/buildp/jenkins/workspace/bs-ui-pipeline/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6636fc51')) {
      api.createRecord('6636fc51', component.options)
    } else {
      api.reload('6636fc51', component.options)
    }
    module.hot.accept("./release-notes.vue?vue&type=template&id=6636fc51&", function () {
      api.rerender('6636fc51', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/release-notes.vue"
export default component.exports