var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "average" }, [
    _c("div", { staticClass: "label" }, [
      _vm._v(_vm._s(_vm.$t("map.popover.averages." + _vm.name))),
    ]),
    _vm.value === undefined || _vm.value == null
      ? _c("div", { staticClass: "reading" }, [
          _vm._v(" " + _vm._s(_vm.$t("map.popover.valueBlank")) + " "),
        ])
      : _c("div", { staticClass: "reading" }, [_vm._v(_vm._s(_vm.value))]),
    _c("div", { staticClass: "color", style: _vm.aqiStyles(_vm.value) }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }