var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-4 ma-4" },
    [
      _c("v-card-title", [
        _vm._v(_vm._s(_vm.$t("developerPanel.createEmTitle"))),
      ]),
      _c(
        "v-card-text",
        { staticClass: "pa-2" },
        [
          _c(
            "v-form",
            { ref: "createForm" },
            [
              _c("v-select", {
                attrs: {
                  label: "Device Type",
                  items: _vm.deviceTypes,
                  "item-text": "name",
                  "item-value": "id",
                  "return-object": "",
                  outlined: "",
                },
                model: {
                  value: _vm.device,
                  callback: function ($$v) {
                    _vm.device = $$v
                  },
                  expression: "device",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Serial Number",
                  outlined: "",
                  rules: [_vm.validationRules.serialNumber],
                },
                on: { input: _vm.validateSerial },
                model: {
                  value: _vm.lastSerial,
                  callback: function ($$v) {
                    _vm.lastSerial = $$v
                  },
                  expression: "lastSerial",
                },
              }),
              _c("v-checkbox", {
                attrs: { label: "Create Multiple Emulators" },
                model: {
                  value: _vm.bulk,
                  callback: function ($$v) {
                    _vm.bulk = $$v
                  },
                  expression: "bulk",
                },
              }),
              _vm.bulk
                ? _c("v-text-field", {
                    attrs: {
                      type: "number",
                      label: "Device Quntity",
                      outlined: "",
                      rules: [_vm.validationRules.quantity],
                    },
                    on: { input: _vm.validateBulk },
                    model: {
                      value: _vm.quantity,
                      callback: function ($$v) {
                        _vm.quantity = $$v
                      },
                      expression: "quantity",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: {
                disabled: !_vm.isSerialValid || (_vm.bulk && !_vm.isBulkValid),
              },
              on: { click: _vm.createEmClick },
            },
            [_vm._v(_vm._s(_vm.$t("buttons.create")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }