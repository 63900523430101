var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        title: _vm.$t("subscriptionBillingInfoDialog.title"),
        useXCloseButton: true,
        "data-cy": "subscriptionBillingInfoDialog",
        icon: "mdi-credit-card",
        fullscreen: _vm.$vuetify.breakpoint.xs,
        persistentOverlay: true,
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("v-spacer"),
              _vm.shouldDisplayBillingUpdateInProgress
                ? _c(
                    "div",
                    {
                      staticClass: "text-center emptyMessage",
                      attrs: { id: "progressSpinner" },
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-spacer"),
              _vm.shouldDisplayUpdateBillingButton
                ? _c(
                    "v-btn",
                    {
                      staticClass: "primary white--text",
                      attrs: {
                        id: "billing-info-update-btn",
                        disabled: _vm.shouldDisableUpdateBillingButton,
                        text: "",
                        "data-cy": "subscriptionBillingInfoUpdateBilling",
                      },
                      on: { click: _vm.updateBilling },
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.save")))]
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("v-card-subtitle", [
        _vm._v(
          " " + _vm._s(_vm.$t("subscriptionBillingInfoDialog.subtitle")) + " "
        ),
      ]),
      _c("v-card-text", [
        _c(
          "div",
          [
            _c(
              "v-radio-group",
              {
                attrs: {
                  row: "",
                  disabled: _vm.shouldDisableSwitchingPaymentTypes,
                },
                model: {
                  value: _vm.paymentType,
                  callback: function ($$v) {
                    _vm.paymentType = $$v
                  },
                  expression: "paymentType",
                },
              },
              [
                _c("v-radio", {
                  attrs: {
                    label: _vm.$t("subscriptionBillingInfoDialog.cardBilling"),
                    value: _vm.paymentTypes.CC,
                    color: "primary",
                    "data-cy": "paymentTypeParameterCC",
                  },
                }),
                _c("v-radio", {
                  attrs: {
                    label: _vm.$t(
                      "subscriptionBillingInfoDialog.purchaseOrderBilling"
                    ),
                    value: _vm.paymentTypes.PO,
                    color: "primary",
                    "data-cy": "paymentTypeParameterPO",
                  },
                }),
              ],
              1
            ),
            _vm.shouldDisplayMonthlyPurchaseOrderIssue
              ? _c(
                  "div",
                  {
                    staticClass: "hint-text",
                    attrs: { id: "billing-info-monthly-purchase-order-issue" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "subscriptionBillingInfoDialog.purchaseOrderMonthlyInfo"
                          )
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.isBillingPurchaseOrder
              ? _c("v-text-field", {
                  staticClass: "text-field billing-info-purchase-order-entry",
                  attrs: {
                    label: _vm.$t(
                      "subscriptionBillingInfoDialog.enterPurchaseOrder"
                    ),
                    maxlength: "35",
                    counter: "35",
                    rules: [_vm.rules.required],
                    disabled: _vm.shouldDisablePurchaseOrderEntry,
                    "data-cy": "billingInfoPurchaseOrderEntry",
                    id: "billing-info-purchase-order-entry",
                  },
                  model: {
                    value: _vm.purchaseOrderReference,
                    callback: function ($$v) {
                      _vm.purchaseOrderReference = $$v
                    },
                    expression: "purchaseOrderReference",
                  },
                })
              : _vm._e(),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.shouldDisplayCreditCardIFrame,
                  expression: "shouldDisplayCreditCardIFrame",
                },
              ],
              attrs: { id: "delegoIFrameHost" },
            }),
            _vm.shouldDisplayCreditCardIFrameLoading
              ? _c(
                  "div",
                  {
                    staticClass: "text-center emptyMessage",
                    attrs: { id: "progressSpinner" },
                  },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.shouldDisplayTemporaryChargeInfo
          ? _c("span", { attrs: { id: "billing-info-cc-temp-charge-info" } }, [
              _vm._v(
                _vm._s(
                  _vm.$t("subscriptionBillingInfoDialog.temporaryChargeInfo")
                )
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }