var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-dialog",
        {
          attrs: {
            open: _vm.open,
            error: _vm.dialogError,
            urlError: _vm.dialogUrlError,
            urlErrorMsg: _vm.dialogUrlErrorMsg,
            "max-width": 800,
            persistentOverlay: true,
            customCss: "register-single-device",
          },
          on: { close: _vm.close },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "v-card-title",
                    [
                      _c("v-icon", { staticClass: "header-icon" }, [
                        _vm._v("mdi-map-marker-plus"),
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("activateDevice.title")) + " "
                      ),
                    ],
                    1
                  ),
                  _c("v-card-subtitle", [
                    _vm._v(_vm._s(_vm.$t("activateDevice.subtitle"))),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "black--text",
                      attrs: {
                        outlined: "",
                        text: "",
                        disabled: _vm.registering,
                        "data-cy": "registerClose",
                      },
                      on: { click: _vm.close },
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.close")))]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary white--text",
                      attrs: {
                        disabled: !_vm.fieldsValid || _vm.registering,
                        loading: _vm.registering,
                        "data-cy": "registerDoneButton",
                        text: "",
                      },
                      on: { click: _vm.save },
                    },
                    [
                      _c("div", { staticClass: "trailing-icon-text" }, [
                        _vm._v(_vm._s(_vm.$t("buttons.done"))),
                      ]),
                      _c("v-icon", { attrs: { right: "", light: "" } }, [
                        _vm._v("mdi-chevron-right"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "v-form",
            { ref: "form", attrs: { "data-cy": "registerDeviceCard" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-2", attrs: { flat: "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "section-padding" },
                            [
                              _c("device-name", {
                                attrs: {
                                  rules: [_vm.rules.required],
                                  cyTag: "deviceName",
                                },
                                model: {
                                  value: _vm.deviceName,
                                  callback: function ($$v) {
                                    _vm.deviceName = $$v
                                  },
                                  expression: "deviceName",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "v-card",
                        { staticClass: "pa-2", attrs: { flat: "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "section-padding" },
                            [
                              _vm.enableDeviceLocationName
                                ? _c("device-location-name", {
                                    attrs: { cyTag: "deviceLocationName" },
                                    model: {
                                      value: _vm.deviceLocationName,
                                      callback: function ($$v) {
                                        _vm.deviceLocationName = $$v
                                      },
                                      expression: "deviceLocationName",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-2", attrs: { flat: "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "section-padding" },
                            [
                              _c(
                                "div",
                                { staticClass: "form-section-header" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("activateDevice.details")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("v-select", {
                                staticClass: "text-field-required",
                                attrs: {
                                  items: _vm.deviceTypeItems,
                                  disabled: _vm.prefilled,
                                  "item-text": "text",
                                  "item-value": "value",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.devicesRemainingValid,
                                  ],
                                  label: _vm.$t("activateDevice.deviceType"),
                                  "data-cy": "deviceTypeSelect",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.deviceTypeChanged()
                                  },
                                },
                                model: {
                                  value: _vm.deviceType,
                                  callback: function ($$v) {
                                    _vm.deviceType = $$v
                                  },
                                  expression: "deviceType",
                                },
                              }),
                              _c("v-text-field", {
                                ref: "idSerialNumberRef",
                                staticClass: "text-field-required",
                                attrs: {
                                  disabled:
                                    _vm.prefilled || _vm.deviceType === "",
                                  label: _vm.$t(
                                    "activateDevice.idSerialNumber"
                                  ),
                                  hint: _vm.serialNumberHintText,
                                  "persistent-hint": "",
                                  counter: _vm.serialLength,
                                  maxlength: _vm.serialLength,
                                  rules: [
                                    _vm.rules.required,
                                    _vm.serialHasValidCharacters,
                                    _vm.rules.serialLength,
                                    _vm.serialHasValidModelNumber,
                                  ],
                                  "data-cy": "deviceSerial",
                                },
                                on: {
                                  keypress: function ($event) {
                                    return _vm.preventNonNumbers($event)
                                  },
                                },
                                model: {
                                  value: _vm.idSerialNumber,
                                  callback: function ($$v) {
                                    _vm.idSerialNumber = $$v
                                  },
                                  expression: "idSerialNumber",
                                },
                              }),
                              _c("br"),
                              _c("v-switch", {
                                attrs: {
                                  label: _vm.$t("activateDevice.isPublic"),
                                  color: "primary",
                                  hint: _vm.$t(
                                    "activateDevice.isPublicDescription"
                                  ),
                                  "persistent-hint": "",
                                  disabled: _vm.isSmartBridge,
                                  "data-cy": "public",
                                },
                                model: {
                                  value: _vm.isPublic,
                                  callback: function ($$v) {
                                    _vm.isPublic = $$v
                                  },
                                  expression: "isPublic",
                                },
                              }),
                              !_vm.isSmartBridge
                                ? _c(
                                    "div",
                                    { staticClass: "secondary-indicator" },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          disabled: _vm.isIndoorOnly,
                                          color: "primary",
                                          hint: _vm.indoorSensorHint,
                                          "persistent-hint": _vm.isIndoorOnly,
                                          "data-cy": "isIndoor",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "activateDevice.indoorSensor"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "device-type-indicator",
                                                    },
                                                    [
                                                      _vm.indoorSensor
                                                        ? _c("img", {
                                                            attrs: {
                                                              src: _vm.indoorPinSrc,
                                                              alt: "Indoor Device Pin",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      !_vm.indoorSensor
                                                        ? _c("img", {
                                                            attrs: {
                                                              src: _vm.outdoorPinSrc,
                                                              alt: "Outdoor Device Pin",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1486355813
                                        ),
                                        model: {
                                          value: _vm.indoorSensor,
                                          callback: function ($$v) {
                                            _vm.indoorSensor = $$v
                                          },
                                          expression: "indoorSensor",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _vm.showSubTierSelect
                        ? _c(
                            "v-card",
                            { staticClass: "pa-2", attrs: { flat: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "section-padding" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-section-header" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "activateDevice.subscription"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("v-select", {
                                    staticClass: "text-field-required",
                                    attrs: {
                                      items: _vm.subscriptionTierItems,
                                      "item-text": "text",
                                      "item-value": "value",
                                      label: _vm.$t(
                                        "activateDevice.subscriptionTier"
                                      ),
                                      hint: _vm.subTierHint,
                                      "persistent-hint": "",
                                      rules: [_vm.rules.required],
                                      "data-cy": "subscriptionTierSelect",
                                    },
                                    model: {
                                      value: _vm.subscriptionTier,
                                      callback: function ($$v) {
                                        _vm.subscriptionTier = $$v
                                      },
                                      expression: "subscriptionTier",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-2",
                          attrs: { disabled: _vm.isSmartBridge, flat: "" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "section-padding" },
                            [
                              _c(
                                "div",
                                { staticClass: "form-section-header" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("activateDevice.location")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("br"),
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "primary--text",
                                      attrs: {
                                        loading: _vm.gettingLocation,
                                        text: "",
                                        disabled: _vm.locationError,
                                        "data-cy": "useBrowserLocation",
                                      },
                                      on: { click: _vm.useBrowserLocation },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("activateDevice.useMyLocation")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "errorMessage" }, [
                                _vm._v(_vm._s(this.locationErrorMessage)),
                              ]),
                              _c(
                                "div",
                                { staticClass: "hint-text hint-text-button" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "activateDevice.useMyLocationHint"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("br"),
                              _c("v-text-field", {
                                ref: "latRef",
                                staticClass: "text-field-required",
                                attrs: {
                                  label: _vm.$t("activateDevice.latitude"),
                                  min: "-90",
                                  max: "90",
                                  maxlength: "12",
                                  rules: [_vm.rules.latitude],
                                  "data-cy": "registerLatitude",
                                },
                                on: {
                                  keypress: function ($event) {
                                    return _vm.preventNonCoords($event)
                                  },
                                },
                                model: {
                                  value: _vm.coords.latitude,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.coords, "latitude", $$v)
                                  },
                                  expression: "coords.latitude",
                                },
                              }),
                              _c("v-text-field", {
                                ref: "lngRef",
                                staticClass: "text-field-required",
                                attrs: {
                                  label: _vm.$t("activateDevice.longitude"),
                                  "persistent-hint": "",
                                  min: "-180",
                                  max: "180",
                                  maxlength: "13",
                                  rules: [_vm.rules.longitude],
                                  "data-cy": "registerLongitude",
                                },
                                on: {
                                  keypress: function ($event) {
                                    return _vm.preventNonCoords($event)
                                  },
                                },
                                model: {
                                  value: _vm.coords.longitude,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.coords, "longitude", $$v)
                                  },
                                  expression: "coords.longitude",
                                },
                              }),
                              _c("div", { staticClass: "hint-text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("activateDevice.coordinateHint")
                                    ) +
                                    " "
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "hint-text-link",
                                    attrs: {
                                      href: _vm.$t(
                                        "activateDevice.coordinateHintLink"
                                      ),
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "activateDevice.coordinateHintLink"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }