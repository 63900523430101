var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "aqi" },
    [
      _c("div", { staticClass: "label label__full" }, [
        _vm._v(" " + _vm._s(_vm.$t("map.popover.currentAqi")) + " "),
      ]),
      _c("div", { staticClass: "label label__abbr" }, [
        _vm._v(" " + _vm._s(_vm.$t("map.popover.abbrevAqi")) + " "),
      ]),
      _c(
        "v-btn",
        {
          staticClass: "reading",
          style: _vm.aqiStyles(_vm.aqi),
          attrs: { text: "", "data-cy": "currentAQI" },
          on: { click: _vm.openAQIDialog },
        },
        [_vm._v(" " + _vm._s(_vm.validateAQI(_vm.aqi)) + " ")]
      ),
      _c("device-aqi-dialog", {
        attrs: { open: _vm.aqiOpen },
        on: {
          close: function ($event) {
            return _vm.closeAQIDialog()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }