var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "black--text",
                        attrs: { text: "", "data-cy": "historyDialogDropdown" },
                        nativeOn: {
                          focus: function ($event) {
                            return $event.target.blur()
                          },
                        },
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("history-menu-items", {
            attrs: {
              passedinParameter: _vm.passedinParameter,
              selectedDevices: _vm.selectedDevices,
              permissions: _vm.permissions,
            },
            on: { parameterChange: _vm.parameterChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }