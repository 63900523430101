var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "menu-dropdown black--text",
                        attrs: { text: "", "data-cy": "apiTableActionMenu" },
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                {
                  attrs: {
                    disabled: _vm.removeLock,
                    link: "",
                    "data-cy": "apiTableApiUnregisterAction",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.openUnregisterDialog(false)
                    },
                  },
                },
                [
                  _c("v-icon", [_vm._v("mdi-map-marker-remove")]),
                  _c("v-list-item-title", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("apiServices.table.unregisterDataServices")
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _vm.hasWebsiteSubscription
                ? _c(
                    "v-list-item",
                    {
                      attrs: {
                        disabled: _vm.removeLock,
                        link: "",
                        "data-cy": "apiTableWebsiteUnregisterAction",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.openUnregisterDialog(true)
                        },
                      },
                    },
                    [
                      !_vm.removeLock
                        ? _c("v-icon", [_vm._v("mdi-map-marker-remove")])
                        : _c("v-progress-circular", {
                            staticClass: "action-item-loading",
                            attrs: {
                              size: 20,
                              indeterminate: "",
                              color: "primary",
                            },
                          }),
                      _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("apiServices.table.unregisterTSILink")
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "v-tooltip",
                    {
                      attrs: {
                        left: "",
                        disabled: !_vm.disableWebsiteRegister(),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-list-item",
                                _vm._g(
                                  {
                                    class: {
                                      restrictedContent:
                                        _vm.disableWebsiteRegister(),
                                    },
                                    attrs: {
                                      disabled: _vm.removeLock,
                                      link: "",
                                      "data-cy":
                                        "apiTableWebsiteRegisterAction",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.openRegisterDialog()
                                      },
                                    },
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", [_vm._v("mdi-map-marker-plus")]),
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "apiServices.table.registerTSILink"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("apiServices.tooltipFreeTier")) +
                            " "
                        ),
                      ]),
                    ]
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c("device-unregister-dialog", {
        attrs: {
          open: _vm.dialogs.deviceUnregisterDialog.open,
          deviceId: _vm.dialogs.deviceUnregisterDialog.deviceId,
          useWebsite: _vm.dialogs.deviceUnregisterDialog.useWebsite,
        },
        on: {
          unregister: _vm.unregisterEvent,
          close: _vm.closeUnregisterDialog,
        },
      }),
      _c("device-register-dialog", {
        attrs: {
          open: _vm.dialogs.registerDeviceDialog.open,
          deviceName: _vm.dialogs.registerDeviceDialog.deviceName,
          deviceModel: _vm.dialogs.registerDeviceDialog.deviceType,
          deviceSerial: _vm.dialogs.registerDeviceDialog.idSerialNumber,
          deviceId: _vm.dialogs.registerDeviceDialog.deviceId,
        },
        on: {
          close: function ($event) {
            _vm.dialogs.registerDeviceDialog.open = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }