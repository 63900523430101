var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("measurement-list", {
    attrs: { measurements: _vm.readingStatistics },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }