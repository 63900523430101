var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-widget",
    {
      attrs: {
        deviceIds: _vm.deviceIds,
        widgetId: _vm.widgetId,
        widgetType: _vm.widgetType,
        widgetMaxDevices: _vm.widgetMaxDevices,
        title: _vm.name,
        subtitle: _vm.deviceType,
        enableDisconnectedDeviceTheme: !_vm.isDeviceConnected,
        isDialog: _vm.isDialog,
        "data-cy": _vm.latestDeviceStudy
          ? "studyWidget"
          : "continuousMonitoringWidget",
      },
      on: {
        close: _vm.close,
        removeWidget: _vm.removeWidget,
        refreshDashboard: _vm.refreshDashboard,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "actions" }, slot: "actions" },
        [
          _c("measurement-options", {
            attrs: {
              deviceIds: _vm.deviceIds,
              passedinParameter: _vm.selectedParameter,
              widgetId: _vm.widgetId,
              filterMeasurementStatistics: true,
            },
            on: { parameterChange: _vm.parameterChange },
          }),
          !_vm.isDialog ? _c("v-divider") : _vm._e(),
        ],
        1
      ),
      _c("popover-widget-toolbar", {
        attrs: { slot: "toolbar", deviceId: _vm.deviceId },
        slot: "toolbar",
      }),
      _c(
        "div",
        {
          staticClass: "toolbar",
          attrs: { slot: "left-toolbar" },
          slot: "left-toolbar",
        },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", "data-cy": "refreshPopover" },
              on: { click: _vm.refresh },
            },
            [
              _c("v-icon", { attrs: { color: "grey" } }, [
                _vm._v("mdi-refresh"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("continuous-monitoring-and-study-content", {
        attrs: {
          slot: "content",
          deviceId: _vm.deviceId,
          isLoading: _vm.isLoading,
          selectedParameter: _vm.selectedParameter,
          latestDeviceStudy: _vm.latestDeviceStudy,
          showStudyContent: !!_vm.lastTelemetryStudyId,
        },
        slot: "content",
      }),
      !_vm.isLoading
        ? _c("popover-last-calibration-timestamp", {
            attrs: { slot: "left-footer", deviceId: _vm.deviceId },
            slot: "left-footer",
          })
        : _vm._e(),
      !_vm.isLoading
        ? _c("popover-current-calibration-factor", {
            attrs: { slot: "right-footer", deviceId: _vm.deviceId },
            slot: "right-footer",
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }