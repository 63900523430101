var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showConnectivity
    ? _c("v-hover", {
        attrs: { "open-delay": 0, "close-delay": 0, value: _vm.val },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (ref) {
                var hover = ref.hover
                return [
                  _c(
                    "span",
                    {
                      attrs: {
                        "data-cy": _vm.connectionType + "ConnectionStatus",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "popover-status-icon",
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.val = !_vm.val
                            },
                          },
                        },
                        [
                          _vm.isDeviceConnected &&
                          _vm.connectionType === _vm.connectionTypes.WIFI
                            ? _c("v-icon", { attrs: { color: "grey" } }, [
                                _vm._v(" " + _vm._s(_vm.connection.icon) + " "),
                              ])
                            : _vm.isDeviceConnected &&
                              _vm.connectionType ===
                                _vm.connectionTypes.ETHERNET
                            ? _c("img", {
                                attrs: {
                                  src: _vm.ethernetIcon,
                                  alt: "Ethernet Icon",
                                },
                              })
                            : _c("v-icon", { attrs: { color: "grey" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.connectionStates.disconnected.icon
                                    ) +
                                    " "
                                ),
                              ]),
                        ],
                        1
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            "content-class": "connection-status-tooltip",
                            value: hover,
                            bottom: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [_c("span", _vm._g({}, on))]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _vm.isDeviceConnected
                            ? _c("div", [
                                _vm._v(" " + _vm._s(_vm.connectionText) + " "),
                              ])
                            : _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.connectionStates.disconnected.label
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          false,
          3293127564
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }