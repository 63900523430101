var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-list" }, [
    _c(
      "ul",
      _vm._l(_vm.errors, function (error, index) {
        return _c("li", { key: index }, [_vm._v(" " + _vm._s(error) + " ")])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }