var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("popover-timestamp", {
    attrs: {
      timestamp: _vm.lastCalibrationTimestamp,
      label: _vm.$t("widget.currentReadings.lastCalibrationDate"),
      customClass: "small-subtitle",
      dataCy: "lastCalibrationDate",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }