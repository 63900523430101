var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { class: { "ma-6": !_vm.$vuetify.breakpoint.xs } },
    [
      _c("v-card-title", { staticClass: "titles" }, [
        _vm._v(" " + _vm._s(_vm.$t("thirdPartyTable.titleThirdParty")) + " "),
      ]),
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-width": "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", "fill-height": "" } },
            _vm._l(_vm.services, function (service, index) {
              return _c(
                "v-col",
                { key: index, attrs: { cols: "auto" } },
                [
                  _c("service-component", {
                    staticClass: "service-component",
                    attrs: {
                      "data-cy": "serviceComponent",
                      serviceName: service.serviceName,
                      serviceUrl: service.serviceUrl,
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }