var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      attrs: {
        "no-gutters": "",
        "data-cy": _vm.showSubscriptionType + "SubscriptionRow",
      },
    },
    [
      _c(
        "v-col",
        { staticClass: "subscription-title", attrs: { cols: "12" } },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(" " + _vm._s(_vm.title) + ": "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showSubscriptionDetails
        ? _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-list",
                { staticClass: "subscription-content" },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("map.deviceInfo.subscriptionTier")
                                ) +
                                ": "
                            ),
                          ]),
                          _c(
                            "v-list-item-subtitle",
                            { attrs: { "data-cy": "subscriptionTier" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.subscriptionDetails.subscriptionTier
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "manageSubscriptions.tableHeaders.renewalDate"
                                  )
                                ) +
                                ": "
                            ),
                          ]),
                          _c(
                            "v-list-item-subtitle",
                            { attrs: { "data-cy": "subscriptionRenewalDate" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.subscriptionDetails.renewalDate) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showSubscriptionDetails
        ? _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-list",
                { staticClass: "subscription-content" },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "manageSubscriptions.tableHeaders.activationDate"
                                  )
                                ) +
                                ": "
                            ),
                          ]),
                          _c(
                            "v-list-item-subtitle",
                            {
                              attrs: {
                                "data-cy": "subscriptionActivationDate",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.subscriptionDetails.activationDate
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "manageSubscriptions.tableHeaders.endDate"
                                  )
                                ) +
                                ": "
                            ),
                          ]),
                          _c(
                            "v-list-item-subtitle",
                            { attrs: { "data-cy": "subscriptionEndDate" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.subscriptionDetails.endDate) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.showSubscriptionDetails
        ? _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "v-list",
                { staticClass: "not-applicable-subscription" },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            {
                              attrs: { "data-cy": "notAvailableSubscription" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("manageDevices.notAvailable")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }