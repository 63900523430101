var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        title: _vm.$t("map.deviceAQI.title"),
        "data-cy": "deviceAQIDialog",
        fullscreen: _vm.$vuetify.breakpoint.xs,
        persistentOverlay: true,
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "v-card-title",
                [
                  _c("v-icon", { staticClass: "header-icon" }, [
                    _vm._v("mdi-information"),
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("map.deviceAQI.title")) + " "),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "actions",
          fn: function () {
            return [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary white--text",
                  attrs: { text: "", "data-cy": "deviceAQIDoneButton" },
                  on: { click: _vm.close },
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.done")) + " ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "aqi-description" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("map.deviceAQI.aqiDescription", {
                measurementType: this.measurementTypeText,
              })
            ) +
            " "
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }