var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "page-container",
      attrs: { justify: "center", align: "center", height: "100%" },
    },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "290" },
          model: {
            value: _vm.showErrorDialog,
            callback: function ($$v) {
              _vm.showErrorDialog = $$v
            },
            expression: "showErrorDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h6" }, [
                _vm._v("Account Creation Error"),
              ]),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    "Unable to create your account for the following reason(s):"
                  ),
                ]),
                _c("p", { staticStyle: { color: "red" } }, [
                  _vm._v(_vm._s(_vm.errorMessage)),
                ]),
                _c("p", [
                  _vm._v(
                    " This should only happen in development. Please ensure the Auth0 account has the required information in 'user_metadata' then come back and refresh the browser. "
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "loading-container" },
        [
          _c("v-card", { staticClass: "loading-card" }, [
            _c(
              "div",
              { staticClass: "progress-container" },
              [
                _c("v-progress-circular", {
                  attrs: { indeterminate: "", color: "primary" },
                }),
                _c(
                  "p",
                  { staticClass: "loading-text text-center" },
                  [
                    _c("i18n", {
                      attrs: { path: "accountCreation.content", tag: "p" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }