var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center", align: "center", height: "100%" } },
    [
      _c(
        "v-card",
        { attrs: { width: "80%" } },
        [
          _c("v-card-title", { staticClass: "title" }, [
            _vm._v("Account Creation"),
          ]),
          _c("v-card-text", [
            _vm._v(
              " Account is being created. Once completed you will be logged out and required to login with your new credentials. You will be redirected to the login page when the countdown reaches zero. "
            ),
          ]),
          _c(
            "div",
            { staticClass: "countdown-container" },
            [
              _c("v-subheader", [_vm._v("Logging out in")]),
              _c("div", { staticClass: "countdown" }, [_vm._v("12")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }