var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "multiple-devices-error-message" }, [
    _c(
      "div",
      { staticClass: "error-header" },
      [
        _c("v-icon", { attrs: { color: "red" } }, [
          _vm._v(" " + _vm._s(_vm.errorIcon) + " "),
        ]),
        _c("div", { staticClass: "error-title" }, [
          _vm._v(" " + _vm._s(_vm.errorTitle) + " "),
        ]),
      ],
      1
    ),
    _vm.errors
      ? _c(
          "div",
          { class: "error-description " + _vm.errorType },
          [
            _vm.errorType === "list"
              ? _c("error-list", { attrs: { errors: this.errors } })
              : _c("span", [
                  _vm._v(" " + _vm._s(_vm.formattedErrors[0]) + " "),
                  _vm.formattedErrors && _vm.formattedErrors.length >= 2
                    ? _c(
                        "a",
                        {
                          staticClass: "download-link",
                          attrs: {
                            href: "csv/Bulk device registration import template.csv",
                            download: "",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.formattedErrors[1]) + " ")]
                      )
                    : _vm._e(),
                  _vm.formattedErrors && _vm.formattedErrors.length >= 3
                    ? _c("span", [
                        _vm._v(" " + _vm._s(_vm.formattedErrors[2]) + " "),
                      ])
                    : _vm._e(),
                ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }