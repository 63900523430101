var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "unregister-warning", attrs: { flat: "" } },
    [
      _c("div", { staticClass: "unregister-title" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("deviceUnregister.unregisterCard.warningTitle")) +
            " "
        ),
      ]),
      _c("div", { staticClass: "unregister-subtitle" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("deviceUnregister.unregisterCard.warningSubtitle")) +
            " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "unregister-message" },
        [
          [
            _vm.hasSBD2
              ? _c("p", { attrs: { "data-cy": "unregisterMessageZero" } }, [
                  _vm._v(" " + _vm._s(_vm.unregisterMessage["partZero"]) + " "),
                ])
              : _vm._e(),
            _c("p", { attrs: { "data-cy": "unregisterMessageOne" } }, [
              _vm._v(" " + _vm._s(_vm.unregisterMessage["partOne"]) + " "),
            ]),
            _c("p", { attrs: { "data-cy": "unregisterMessageTwo" } }, [
              _vm._v(" " + _vm._s(_vm.unregisterMessage["partTwo"]) + " "),
            ]),
          ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }