var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        "data-cy": "subscriptionExpiredDialog",
        fullscreen: _vm.$vuetify.breakpoint.xs,
        persistentOverlay: true,
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "v-card-title",
                { attrs: { "data-cy": "subscriptionExpiredHeader" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("subscriptionExpiredDialog.title")) +
                      " "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "actions",
          fn: function () {
            return [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary white--text",
                  attrs: { text: "", "data-cy": "subscriptionExpiredClose" },
                  on: { click: _vm.close },
                },
                [_vm._v(_vm._s(_vm.$t("buttons.done")))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("v-card-text", [
        _vm._v(_vm._s(_vm.$t("subscriptionExpiredDialog.message"))),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }