var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "upload-csv-file",
      on: {
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
        click: _vm.onClickHandler,
      },
    },
    [
      _c("v-file-input", {
        ref: "fileInput",
        staticClass: "add-file-input",
        attrs: {
          "prepend-icon": "mdi-plus-circle-outline",
          accept: "text/csv",
          label: _vm.hover
            ? _vm.$t("registerMultipleDevices.selectFile")
            : _vm.$t("registerMultipleDevices.browseFiles"),
          value: _vm.value,
        },
        on: { change: _vm.onChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }