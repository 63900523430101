var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { class: { "ma-6": !this.$vuetify.breakpoint.xs } },
    [
      _c("v-card-title", { staticClass: "titles" }, [
        _vm._v(" " + _vm._s(_vm.$t("thirdPartyTable.titleTSI")) + " "),
      ]),
      _c("v-container", { attrs: { fluid: "" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }