var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        "data-cy": "apiBuilkRegistrationInfoDialog",
        fullscreen: _vm.$vuetify.breakpoint.xs,
        persistentOverlay: true,
        "max-width": _vm.maxWidth,
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "v-card-title",
                { attrs: { "data-cy": "subscriptionExpiredHeader" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("bulkRegistrationInfoDialog.title")) +
                      " "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "actions",
          fn: function () {
            return [
              _vm.allowRetry
                ? _c(
                    "v-btn",
                    {
                      staticClass: "primary white--text",
                      attrs: {
                        loading: _vm.isRetrying,
                        text: "",
                        "data-cy": "apiBulkRegistrationRetry",
                      },
                      on: { click: _vm.retry },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("buttons.retryFailedReg")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary white--text",
                  attrs: {
                    disabled: _vm.isRetrying,
                    text: "",
                    "data-cy": "apiBulkRegistrationClose",
                  },
                  on: { click: _vm.close },
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.done")) + " ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "v-card-text",
        [
          !_vm.isMobile
            ? _c("v-simple-table", {
                attrs: { dense: "", "data-cy": "registrationReportDesktop" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c("thead", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "text-left",
                                  attrs: { scope: "col" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("apiServices.table.serialNumber")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "text-left",
                                  attrs: { scope: "col" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("apiServices.table.dataServices")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm.addToBoth
                                ? _c(
                                    "th",
                                    {
                                      staticClass: "text-left",
                                      attrs: { scope: "col" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "apiServices.table.websiteSubs"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.results, function (result) {
                              return _c("tr", { key: result.serial }, [
                                _c("td", [_vm._v(_vm._s(result.serial))]),
                                _c("td", [
                                  _c(
                                    "span",
                                    {
                                      class: _vm.assignClass(
                                        result[
                                          _vm.subscriptionTypes.DATA_SERVICES
                                        ].success
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            result[
                                              _vm.subscriptionTypes
                                                .DATA_SERVICES
                                            ].msg
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm.addToBoth
                                  ? _c("td", [
                                      _c(
                                        "span",
                                        {
                                          class: _vm.assignClass(
                                            result[
                                              _vm.subscriptionTypes.WEBSITE
                                            ].success
                                          ),
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                result[
                                                  _vm.subscriptionTypes.WEBSITE
                                                ].msg
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            }),
                            0
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2033828317
                ),
              })
            : _vm._e(),
          _vm.isMobile
            ? _c(
                "div",
                {
                  staticClass: "list",
                  attrs: { "data-cy": "registrationReportMobile" },
                },
                _vm._l(_vm.results, function (result) {
                  return _c(
                    "div",
                    { key: result.serial, staticClass: "list-item" },
                    [
                      _c("div", { staticClass: "list-item__group" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "list-item__content list-item__serial-number",
                          },
                          [
                            _c("div", { staticClass: "list-item__title" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("apiServices.table.serialNumber")
                                  ) +
                                  ":  "
                              ),
                            ]),
                            _c("div", { staticClass: "list-item__body" }, [
                              _vm._v(" " + _vm._s(result.serial) + " "),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "list-item__content" }, [
                          _c("div", { staticClass: "list-item__title" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("apiServices.table.dataServices")
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "list-item__body" }, [
                            _c(
                              "span",
                              {
                                class: _vm.assignClass(
                                  result[_vm.subscriptionTypes.DATA_SERVICES]
                                    .success
                                ),
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      result[
                                        _vm.subscriptionTypes.DATA_SERVICES
                                      ].msg
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm.addToBoth
                          ? _c("div", { staticClass: "list-item__content" }, [
                              _c("div", { staticClass: "list-item__title" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("apiServices.table.websiteSubs")
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "list-item__body" }, [
                                _c(
                                  "span",
                                  {
                                    class: _vm.assignClass(
                                      result[_vm.subscriptionTypes.WEBSITE]
                                        .success
                                    ),
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          result[_vm.subscriptionTypes.WEBSITE]
                                            .msg
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }