var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.zeroValue
    ? _c(
        "v-card",
        {
          staticClass: "calibration-card",
          attrs: { flat: "", "data-cy": "addUserZeroConfirmationText" },
        },
        [
          _c("v-card-text", { staticClass: "tab-content__title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "map.deviceSettings.zeroing.confirmation.userZero.title"
                  )
                ) +
                " "
            ),
          ]),
          _c("v-card-text", { staticClass: "paragraph-text" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "map.deviceSettings.zeroing.confirmation.userZero.paragraph1"
                  )
                ) +
                " "
            ),
          ]),
          _c("v-card-text", { staticClass: "paragraph-text" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "map.deviceSettings.zeroing.confirmation.userZero.paragraph2"
                  )
                ) +
                " "
            ),
          ]),
        ],
        1
      )
    : _c(
        "v-card",
        {
          staticClass: "calibration-card",
          attrs: { flat: "", "data-cy": "removeUserZeroConfirmation" },
        },
        [
          _c("v-card-text", { staticClass: "tab-content__title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "map.deviceSettings.zeroing.confirmation.factoryZero.title"
                  )
                ) +
                " "
            ),
          ]),
          _c("v-card-text", { staticClass: "paragraph-text" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "map.deviceSettings.zeroing.confirmation.factoryZero.paragraph1"
                  )
                ) +
                " "
            ),
          ]),
          _c("v-card-text", { staticClass: "paragraph-text" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "map.deviceSettings.zeroing.confirmation.factoryZero.paragraph2"
                  )
                ) +
                " "
            ),
          ]),
          _c("v-card-text", { staticClass: "paragraph-text" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "map.deviceSettings.zeroing.confirmation.factoryZero.paragraph3"
                  )
                ) +
                " "
            ),
          ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }