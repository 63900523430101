var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-textarea", {
    staticClass: "text-field-required",
    attrs: {
      label: _vm.$t("activateDevice.deviceName"),
      maxlength: _vm.maxLength,
      counter: _vm.maxLength,
      rules: _vm.rules,
      rows: "1",
      "auto-grow": "",
      "data-cy": _vm.cyTag,
    },
    on: {
      keypress: function ($event) {
        return _vm.preventDeniedCharacters($event)
      },
    },
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v
      },
      expression: "localValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }