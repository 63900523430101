var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("aqi-measurement", {
        ref: "aqiMeasurement",
        attrs: { deviceId: _vm.id },
      }),
      _c("popover-measurements", {
        attrs: {
          deviceId: _vm.id,
          permissions: _vm.permissions,
          sensorReadings: _vm.sensorReadings,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }