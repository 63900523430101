var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.canAccess(_vm.access.FULL, _vm.accessLevel)
      ? _c(
          "span",
          { staticClass: "condition-icons-wrapper" },
          [
            _c("connection-status", { attrs: { deviceId: _vm.deviceId } }),
            _c("battery-status", { attrs: { deviceId: _vm.deviceId } }),
            _c("eol-alert", { attrs: { deviceId: _vm.deviceId } }),
            _c("device-alerts", { attrs: { deviceId: _vm.deviceId } }),
            _c("communication-status", { attrs: { deviceId: _vm.deviceId } }),
            _c("offline-studies-status", { attrs: { deviceId: _vm.deviceId } }),
            _c("live-studies-status", { attrs: { deviceId: _vm.deviceId } }),
          ],
          1
        )
      : _c("span", { staticClass: "not-available-text" }, [
          _vm._v(" " + _vm._s(_vm.$t("manageDevices.notAvailable")) + " "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }