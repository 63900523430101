var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        "data-cy": "removeWarningDialog",
        persistentOverlay: true,
        fullscreen: _vm.$vuetify.breakpoint.xs,
        "content-class": "removeWarning",
        maxWidth: 450,
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "black--text dialog-btn",
                  attrs: {
                    "v-if": _vm.$vuetify.breakpoint.xs,
                    text: "",
                    outlined: "",
                    "data-cy": "removeWidgetCancel",
                  },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "primary white--text dialog-btn",
                  attrs: { text: "", "data-cy": "removeWidgetOK" },
                  on: { click: _vm.remove },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("dashboard.removeWarningDialog.removeButton")
                      ) +
                      " "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "v-card-title",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("v-icon", { staticClass: "header-icon" }, [_vm._v("mdi-alert")]),
          _vm._v(
            " " + _vm._s(_vm.$t("dashboard.removeWarningDialog.title")) + " "
          ),
        ],
        1
      ),
      _c("v-card-text", [
        _vm._v(
          " " + _vm._s(_vm.$t("dashboard.removeWarningDialog.message")) + " "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }