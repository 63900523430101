var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": _vm.contentClass,
        "max-width": _vm.maxWidth ? _vm.maxWidth : 520,
        "max-height": _vm.maxHeight ? _vm.maxHeight : 800,
        fullscreen: _vm.$vuetify.breakpoint.xs,
        "hide-overlay": _vm.persistent,
        persistent: _vm.persistent || _vm.persistentOverlay,
        "no-click-animation": _vm.persistent,
        scrollable: "",
      },
      on: { input: _vm.close },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              return [_vm._t("activator", null, null, on)]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "v-card",
        {
          class: [
            {
              mobile: _vm.$vuetify.breakpoint.xs,
              "dialog-card--shaded": _vm.shaded,
            },
            _vm.customCss,
          ],
        },
        [
          !_vm.noHeader
            ? _vm._t("header", function () {
                return [
                  _c(
                    "v-card-title",
                    [
                      _vm.icon
                        ? _c("v-icon", { staticClass: "header-icon" }, [
                            _vm._v(_vm._s(_vm.icon)),
                          ])
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.title) + " "),
                      _vm.useXCloseButton ? _c("v-spacer") : _vm._e(),
                      _vm.useXCloseButton
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                "data-cy": "baseDialogXCloseButton",
                              },
                              on: { click: _vm.close },
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              })
            : _vm._e(),
          _c(
            "fusion-scroll",
            [
              _c(
                "v-card-text",
                {
                  class: {
                    noHeader: _vm.noHeader,
                    mobile: _vm.$vuetify.breakpoint.xs,
                    padded: _vm.padded,
                  },
                },
                [_vm._t("default")],
                2
              ),
            ],
            1
          ),
          _vm.showErrorsBlock
            ? _c(
                "v-card-actions",
                { staticClass: "errors", attrs: { text: "" } },
                [
                  _vm.error
                    ? _c("div", { staticClass: "hint-text err-font" }, [
                        _vm._v(" " + _vm._s(_vm.error) + " "),
                        _vm.urlError != ""
                          ? _c("a", { attrs: { href: _vm.urlError } }, [
                              _vm._v(" " + _vm._s(_vm.urlErrorMsg) + " "),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.secondaryError
                    ? _c("div", { staticClass: "hint-text err-font ml-auto" }, [
                        _vm._v(" " + _vm._s(_vm.secondaryError) + " "),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          !_vm.noActions
            ? _c(
                "v-card-actions",
                { staticClass: "actions", attrs: { text: "" } },
                [_vm._t("actions")],
                2
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }