var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-widget",
    {
      class: [_vm.sizeClass],
      attrs: {
        deviceIds: _vm.deviceIds,
        widgetId: _vm.widgetId,
        widgetType: _vm.widgetType,
        widgetMaxDevices: _vm.widgetMaxDevices,
        title: _vm.name,
        subtitle: _vm.deviceType,
        fullscreen: _vm.fullscreen,
        observeSize: !_vm.isDialog,
        isDialog: _vm.isDialog,
        "data-cy": "currentReadingsWidget",
      },
      on: {
        close: _vm.close,
        removeDevice: _vm.removeDevice,
        removeWidget: _vm.removeWidget,
        widgetSizeChanged: _vm.updateWidgetSize,
        refreshDashboard: _vm.refreshDashboard,
      },
    },
    [
      _c("popover-widget-toolbar", {
        attrs: { slot: "toolbar", deviceId: _vm.deviceId },
        slot: "toolbar",
      }),
      _c(
        "div",
        {
          staticClass: "toolbar",
          attrs: { slot: "left-toolbar" },
          slot: "left-toolbar",
        },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", "data-cy": "refreshPopover" },
              on: { click: _vm.refresh },
            },
            [
              _c("v-icon", { attrs: { color: "grey" } }, [
                _vm._v("mdi-refresh"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("popover-main-content", {
        ref: "popoverMain",
        attrs: {
          slot: "content",
          deviceId: _vm.deviceId,
          permissions: _vm.permissions,
        },
        slot: "content",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }