var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        hideOverlay: false,
        persistent: true,
        "data-cy": "currentReadingsDialog",
        "max-width": "500",
      },
      on: { input: _vm.close },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              return [_vm._t("activator", null, null, on)]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("current-readings-widget", {
            attrs: { deviceIds: _vm.deviceIds, isDialog: true },
            on: { close: _vm.close },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }