var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showIcon
    ? _c("v-hover", {
        attrs: { "open-delay": 0, "close-delay": 0, value: _vm.val },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (ref) {
                var hover = ref.hover
                return [
                  _c(
                    "span",
                    { attrs: { "data-cy": _vm.dataCy } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "popover-status-icon",
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.val = !_vm.val
                            },
                          },
                        },
                        [
                          _vm.icon
                            ? _c(
                                "v-icon",
                                { attrs: { color: _vm.iconColor } },
                                [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                              )
                            : _c("img", {
                                attrs: { src: _vm.imgSrc, alt: _vm.imgAlt },
                              }),
                        ],
                        1
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            "content-class": _vm.tooltipContentClass,
                            value: hover,
                            bottom: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [_c("span", _vm._g({}, on))]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v(" " + _vm._s(_vm.iconText) + " ")])]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          false,
          1818474387
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }