var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "multiple-devices-summary-message" },
    [
      _c("icon-with-message", {
        attrs: {
          color: "green",
          icon: "mdi-check-circle",
          message: _vm.formatMessage(
            _vm.$t("registerMultipleDevices.devicesRegistered"),
            _vm.registeredDevices
          ),
          "data-cy": "registeredDevices",
          "data-devices": _vm.registeredDevices,
        },
      }),
      _c("icon-with-message", {
        attrs: {
          color: "red",
          icon: "mdi-alert-circle",
          message: _vm.failedFormattedMessage,
          "data-cy": "failedDevices",
          "data-devices": _vm.failedDevices,
        },
      }),
      _vm.showErrorList
        ? _c("error-list", { attrs: { errors: _vm.errors } })
        : _vm._e(),
      _vm.showNotProcessedDevices
        ? _c("icon-with-message", {
            attrs: {
              color: "orange darken-2",
              icon: "mdi-alert",
              message: _vm.formatMessage(
                _vm.$t("registerMultipleDevices.devicesNotProcessed"),
                _vm.notProcessedDevices
              ),
              "data-cy": "notProcessedDevices",
              "data-devices": _vm.notProcessedDevices,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }