var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        open: _vm.open,
        title: _vm.$t("map.deviceSettings.title"),
        icon: "mdi-delete",
        "data-cy": "deviceUnregisterDialog",
        fullscreen: _vm.$vuetify.breakpoint.xs,
        persistentOverlay: true,
      },
      on: { close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("device-unregister-actions", {
                attrs: { deviceId: _vm.deviceId, useWebsite: _vm.useWebsite },
                on: { unregister: _vm.handleUnregister, close: _vm.close },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("device-unregister-card", {
        attrs: { deviceId: _vm.deviceId, useWebsite: _vm.useWebsite },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }