var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { left: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.$store.state.map.movingMode,
                            expression: "!$store.state.map.movingMode",
                          },
                        ],
                        class: [
                          { restrictedContent: _vm.showRestricted },
                          { accent: !_vm.showRestricted },
                        ],
                        attrs: {
                          id: "registerDeviceButton",
                          fab: "",
                          "data-cy": "openRegisterDevice",
                        },
                        on: { click: _vm.open },
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-map-marker-plus")])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showRegisterToolTip,
            callback: function ($$v) {
              _vm.showRegisterToolTip = $$v
            },
            expression: "showRegisterToolTip",
          },
        },
        [_c("span", [_vm._v(_vm._s(_vm.registerButtonTooltip))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }