var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "min-width": 260,
        "max-width": _vm.$vuetify.breakpoint.xs ? "100%" : 350,
        "nudge-bottom": _vm.$vuetify.breakpoint.xs ? 0 : 4,
        "nudge-right": _vm.$vuetify.breakpoint.xs ? 0 : 60,
        bottom: _vm.$vuetify.breakpoint.xs ? false : true,
        "offset-y": _vm.$vuetify.breakpoint.xs ? false : true,
        left: _vm.$vuetify.breakpoint.xs ? false : true,
        "max-height": _vm.$vuetify.breakpoint.xs ? "100%" : "600px",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _vm.$auth.isAuthenticated
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.haveNotes,
                              expression: "haveNotes",
                            },
                          ],
                          staticStyle: {
                            height: "100%",
                            "margin-right": "16px",
                          },
                          attrs: {
                            text: "",
                            "data-cy": "globalAlertBellButton",
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-badge",
                        {
                          attrs: { color: "accent", overlap: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "badge",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.notificationsNum)),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("v-icon", [_vm._v("mdi-bell")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _c(
        "v-card",
        {
          style: _vm.$vuetify.breakpoint.xs ? "border-radius:unset;" : "",
          attrs: { height: _vm.$vuetify.breakpoint.xs ? "100%" : "unset" },
        },
        [
          _vm.$vuetify.breakpoint.xs
            ? [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c("v-card-subtitle", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("alertHistory.notificationTitle")) +
                          " "
                      ),
                    ]),
                    _c("v-spacer"),
                    _c(
                      "v-icon",
                      {
                        on: {
                          click: function ($event) {
                            _vm.menu = false
                          },
                        },
                      },
                      [_vm._v("mdi-close")]
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "v-card-subtitle",
                  { staticClass: "btn-subtitle" },
                  [
                    _c("div", [
                      _vm._v(_vm._s(_vm.$t("alertHistory.notificationTitle"))),
                    ]),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary--text mobile-menu",
                        attrs: { text: "", "data-cy": "globalAlertViewAll" },
                        on: { click: _vm.viewAll },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("alertHistory.viewAll")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
          _c(
            "v-list",
            _vm._l(_vm.notifications, function (item, index) {
              return _c(
                "v-list-item",
                { key: index, attrs: { "three-line": "" } },
                [
                  _c(
                    "v-list-item-content",
                    [
                      index > 0
                        ? _c("v-divider", {
                            staticStyle: { "margin-bottom": "20px" },
                          })
                        : _vm._e(),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.trigger.title)),
                              ]),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass: "anchor-right",
                                  staticStyle: {
                                    "text-transform": "capitalize",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.trigger.severity) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-list-item-subtitle", [
                                _vm._v(" " + _vm._s(item.device.name) + " "),
                              ]),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-list-item-subtitle",
                                { staticClass: "anchor-right" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        new Date(
                                          item.triggeredAt
                                        ).toLocaleDateString()
                                      ) +
                                      " " +
                                      _vm._s(
                                        new Date(
                                          item.triggeredAt
                                        ).toLocaleTimeString()
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("v-list-item-action-text", [
                                _c("strong", [
                                  _vm._v(" " + _vm._s(item.message) + " "),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "clear-button",
                                  attrs: {
                                    loading: _vm.buttonLoading["" + item.id],
                                    disabled: _vm.clearingInProgress,
                                    outlined: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clear(item.id)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("buttons.clear")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "stick-bottom" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "clear-all-button black white--text",
                  attrs: {
                    loading: _vm.clearingAll,
                    disabled: _vm.clearingInProgress,
                    text: "",
                  },
                  on: { click: _vm.clearAll },
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.clearAll")) + " ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }